import api from './api'

export default {
  create (data) {
    return api().post('api/form/create/contract', data)
  },

  send (data) {
    return api().post('api/form/contact', data)
  }
}
