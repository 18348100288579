<template>
    <div class="wrapper">
        <div class="section-brand-2">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-left">
                        <div class="content-center">
                            <h1 class="title">UK Modern Slavery Statement</h1>
                        </div>
                        <br><br>
                        <h2>Our Modern Slavery Statement</h2>
                        <p>
                            We adopt a zero tolerance to modern slavery, human trafficking and all forms of
                            corruption and bribery directly and indirectly associated with fraudulent or criminal
                            acts and financial crime. We fully support the government’s objectives to eradicate
                            modern slavery and human trafficking. We set high standards of impartiality, integrity
                            and objectivity and we will ensure that our activities operate to the highest level of
                            ethical standards and sound governance arrangements
                        </p>

                        <br><br>

                        <h2>Our Commitment</h2>
                        <p>
                            We are committed to improving our practices to combat slavery and human trafficking.
                            We recognise that slavery and human trafficking is a real yet hidden issue in our society.
                            We will not tolerate slavery and human trafficking in our business or supply chain. We are
                            committed to acting ethically and with integrity in all our business dealings and relationships
                            and to implementing and enforcing effective systems and controls to ensure that any form of slavery
                            is not taking place in our own business or supply chains.
                        </p>
                        <br>
                        <br>
                        <h2>Organisation's structure</h2>
                        <p>
                            We are a service-led, niche Umbrella Payroll provider to Employment Agencies in both the Public and Private Sectors.
                            Our business operates through three main departments, which offers very high service unparallel in the industry. 
                        </p>

                        <br><br>

                        <p>
                            1. Specials departments – Our main operational departments are Compliance, Customer Services and Payroll which interact regularly with the market.
                            The departments offers services to enhance Public satisfaction. We service a growing market niche. 
                        </p>

                        <br><br>

                        <p>
                            2. Policies
                            <br>

                            We operate a number of internal policies to ensure that we are conducting business in an ethical and transparent manner. The policies include:
                            <ol type="a">
                                <li>
                                    Recruitment – we operate a robust recruitment policy, including conducting eligibility to work in the UK checks for all employees to
                                    safeguard against human trafficking or individuals being forced to work against their will.
                                </li>
                                <li>
                                    Whistleblowing – our whistleblowing policy ensures that all employees know that they can raise concerns about
                                    how colleagues are being treated, or practices within our business or supply chain, without fear of reprisal.
                                </li>
                                <li>
                                    Health and Safety – this policy sets out our approach to ensure that we provide a healthy working
                                    environment for our staff and contractors that work out of our premises. 
                                </li>
                            </ol>
                        </p>

                        <br><br>

                        <h2>Our supply chains</h2>
                        <p>
                            Our supply chains are based throughout the country and include suppliers of direct materials for both private and public consumption.
                            The various Agencies that we work with ensures that the chain is managed to our high standards.
                        </p>
                        <br><br>
                        <p>
                            We conduct due diligence on all suppliers assessed by our Quality Team which is critical to our business before allowing any business interaction.
                            This due diligence includes assessing regulatory licences and certificates, compliance with relevant regulatory bodies,
                            checks to determine the financial stability of the supplier as well as carrying out supplier audits, as appropriate.
                        </p>

                        <br><br>

                        <h2>Creating Transparency</h2>
                        <p>
                            We create transparency in our chain by
                            <br>
                            <ol>
                                <li>Partnering with transparent, sustainable suppliers in sourcing and delivery of services</li>
                                <li>Tracing source of labour hired in our Industry with the view to eliminate any malpractices</li>
                                <li>Creating open lines of communication with both suppliers and the various stakeholders</li>
                                <li>Storing and sharing data about supply chain</li>
                            </ol>
                        </p>

                        <br><br>

                        <h2>Further steps</h2>
                        <p>
                            We intend to take the following further steps to combat slavery and human trafficking:
                            <br>
                            <ol>
                                <li>
                                    Risk assess all new suppliers using the Global Slavery Index and ask them to
                                    certify that they have taken the necessary steps to eradicate modern slavery
                                    within their own organisation and supply chains.
                                </li>
                                <li>
                                    Notify all existing suppliers of our high expectations and their obligations
                                    in relation to the prohibition of modern slavery and human trafficking.
                                </li>
                                <li>
                                    Incorporate anti-slavery and human trafficking obligations into procurement
                                    agreements and subcontracting arrangements on a risk assessed basis. 
                                </li>
                                <li>
                                    Incorporate appropriate measures in our due diligence processes for sourcing
                                    suppliers, subcontractors and acquisitions on a risk assessed basis.
                                </li>
                                <li>
                                    Provide training to relevant Company employees to ensure that high level
                                    understanding of the risks of modern slavery and human trafficking. 
                                </li>
                                <li>
                                    Deliberate and appropriate inclusion and reference to the Modern Slavery
                                    Act 2015 in our policies and procedures. 
                                </li>
                            </ol>
                            <br>
                            This statement is made pursuant to section 54(1) of the Modern Slavery Act 2015 and
                            constitutes our Company's slavery and human trafficking statement for the current financial year. 
                        </p>

                        <br><br>
                        <p>Signed</p>
                        <p style="font-family: 'Herr Von Muellerhoff', cursive; font-size: 30px;">Arnold Mudzengerere</p>
                        <p>Arnold Mudzengerere</p>
                        <p>Date: March 2023</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  export default {
  }
</script>
<style scoped>
p {
    color: #fff;
    font-size: 1.3em;
    font-weight: 400;
}
</style>
