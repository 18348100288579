import { render, staticRenderFns } from "./StaffHandbook.vue?vue&type=template&id=0d158920"
import script from "./StaffHandbook.vue?vue&type=script&lang=js"
export * from "./StaffHandbook.vue?vue&type=script&lang=js"
import style0 from "./StaffHandbook.vue?vue&type=style&index=0&id=0d158920&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports