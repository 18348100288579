<template>
    <div class="wrapper">
        <div class="page-header">
            <parallax class="page-header-image section-image-primary"
                 style="background-image: url('img/bg21.jpg')">
            </parallax>
            <div class="content-center">
                <h1 class="title">Staff Handbook</h1>
                <br><br>
                <h3>As an employee of GCIPLTD Umbrella, yours and our responsibilities are outlined in your staff handbook which includes the basis upon which you may claim allowable expenses or take out a grievance.
                <br><br>
                Please take a moment to read the staff handbook below.</h3>
            </div>
        </div>
        <div class="section-brand-2">
            <div class="container">
                <div class="row">
                  <div class="col-md-6">
                    <navbar position="fixed" menu-classes="ml-auto">
                        <div class="text-center">
                          <ul class="nav flex-column nav-list">
                            <li class="nav-item active">
                              <a class="nav-link" href="#1"><!--i class="now-ui-icons travel_info"></i-->1.0 About Us</a>
                            </li>
                            <li class="nav-item active">
                              <a class="nav-link" href="#2">2.0 Holidays and Leave</a>
                            </li>
                            <li class="nav-item active">
                              <a class="nav-link" href="#3">3.0 Sickness</a>
                            </li>
                            <li class="nav-item active">
                              <a class="nav-link" href="#4">4.0 Maternity, Paternity And Adoption Pay</a>
                            </li>
                            <li class="nav-item active">
                              <a class="nav-link" href="#5">5.0 Expenses</a>
                            </li>
                            <li class="nav-item active">
                              <a class="nav-link" href="#6">6.0 Code of Conduct</a>
                            </li>
                          </ul>
                        </div>
                      </navbar>
                  </div>

                  <div class="col-md-6">
                    <navbar position="fixed" menu-classes="ml-auto">
                      <div class="text-center">
                        <ul class="nav flex-column nav-list">
                          <li class="nav-item active">
                            <a class="nav-link" href="#7">7.0 Disciplinary Procedure</a>
                          </li>
                          <li class="nav-item active">
                            <a class="nav-link" href="#8">8.0 Grievance Procedure</a>
                          </li>
                          <li class="nav-item active">
                            <a class="nav-link" href="#9">9.0 Pension</a>
                          </li>
                          <li class="nav-item active">
                            <a class="nav-link" href="#10">10.0 Data Protection Notice</a>
                          </li>
                          <li class="nav-item active">
                            <a class="nav-link" href="#11">11.0 Insurance Cover</a>
                          </li>
                          <li class="nav-item active">
                            <a class="nav-link" href="#12">12.0 Criminal Finance Act – Part 3 Procedure</a>
                          </li>
                        </ul>
                      </div>
                    </navbar>
                  </div>

                    <div class="col-md-12 ml-auto mr-auto">
                      <div id="1" class="link-pad">
                        <h3 class="title">1.0 About Us</h3>
                        <h5>
                        <strong>1.1</strong> Welcome to the Global Challenge Investments Pvt Ltd Umbrella team, we are happy to have you on board.
                        <br><br>
                        <strong>1.2</strong> As you will by now have realised, all umbrella payroll companies are not the same and their varying practices can have a very positive or very negative impact upon you, both now and in the future. This is what makes Global Challenge Investments Pvt Ltd Umbrella so refreshing as our impact is always and most definitely a positive one.
                        <br><br>
                        <strong>1.3</strong> Global Challenge Investments Pvt Ltd Umbrella started out of a demand for compliant umbrella payroll services that put customer’s (staff’s) interests first. Providing a brand and a service that everyone can trust and use safe in the knowledge that everything we do is compliant and in your best interests.
                        <br><br>
                        <strong>1.4</strong> After our formation we have experienced rapid growth due to our ability to tailor our services to meet the needs of our staff. As a business we do not advertise so our growth has been based solely upon the personal recommendations of our staff who make referrals to us after enjoying the benefits of our services.
                        <br><br>
                        <strong>1.5</strong> We work out of our head office in Kent and use only ethical practices.
                          <br/>
                          <br/>
                        </h5>
                      </div>

                      <div id="2" class="link-pad">
                        <h3 class="title">2.0 Holidays and Leave</h3>
                        <h5>
                        <strong>2.1</strong> We are firmly committed to the fair payment of holiday pay and to the protection of holiday pay rights of our employees. We pay 100% of holiday pay due with no exceptions and we monitor the working patterns of our employees to ensure holiday is taken in line with UK Working Time Legislation.
                        <br><br>
                        <strong>2.2</strong> Please inform us of any dates that you will be unavailable for work, for example if you intend to take a short break or holiday.
                        <br><br>
                        <strong>2.3</strong> A holiday form can be requested by calling Umbrella Services on <strong>01322 272 859</strong> or via email: <a href="mailto:info@gcipltd.com">info@gcipltd.com</a>
                        <br><br>
                        <strong>2.4</strong> Useful info:
                        <ul>
                            <li>I. We use electronic forms which will be sent to your email address via adobe sign.</li>
                            <li>II. Holiday pay is accrued at <strong>12.07%</strong> of your gross in line with BERR guidelines and held for you until you request it.</li>
                            <li>III. Your available holiday pay is shown in the bottom right hand corner of your payslip under the amount you received for your take home pay.</li>
                            <li>IV. If you leave GCIPLTD Umbrella we will pay out automatically any holiday pay accrued when we issue a P45.</li>
                        </ul>
                        </h5>
                      </div>

                      <div id="3" class="link-pad">
                        <h3 class="title">3.0 Sickness</h3>
                        <h5>
                        <strong>3.1</strong> Should you fall ill for four consecutive days, we will administer Statutory Sick Pay (SSP) in accordance with Government guidelines.
                        <br><br>
                        <strong>3.2</strong> Please speak to the Umbrella Services on <strong>01322 272 859</strong> for more information.
                        <br><br>
                        <strong>3.3</strong> If you are in any doubt as to your entitlement, please refer to HM Revenue &amp; Customs’ website at <a href="https://www.gov.uk/government/organisations/hm-revenue-customs" target="_blank">www.hmrc.gov.uk</a>. Some state credits and benefits are calculated according to your earnings which are liable to National Insurance. Please note that your earnings, which are liable to National Insurance, will be reduced if you are claiming expenses each week / month and this may affect the amount of benefits you receive.
                        <br><br>
                        <strong>3.4</strong> In order to make a claim you must telephone on the first day of sickness absence to say what is wrong with you and when you expect to return.
                        <br><br>
                        <strong>3.5</strong> If you are unable to advise us of your expected day of return at the time of first notifying of your sickness absence, then please telephone every day of your absence until you can. We must know at all times when to expect you back.
                        <br><br>
                        <strong>3.6</strong> Please contact Umbrella Services for a Sickness Form on <strong>01322 272 859</strong>.
                        </h5>
                      </div>

                      <div id="4" class="link-pad">
                        <h3 class="title">4.0 Maternity, Paternity And Adoption Pay</h3>
                        <h5>
                        <strong>4.1</strong> Provided you satisfy the appropriate conditions regarding earnings and periods of employment etc, you may be entitled to Statutory Maternity Pay (SMP), Statutory Paternity Pay (SPP) or Statutory Adoption Pay (SAP).
                        <br><br>
                        <strong>4.2</strong> Please contact Umbrella Services on <strong>01322 272 859</strong> to advise and assess your eligibility.
                        <br><br>
                        <strong>4.3</strong> If you are in any doubt as to your entitlement please refer to HM Revenue &amp; Customs website at <a href="https://www.gov.uk/government/organisations/hm-revenue-customs">www.hmrc.gov.uk</a>.
                        <br><br>
                        <strong>4.4</strong> Some state credits and benefits are calculated according to your earnings which are liable to National Insurance. Please note that your earnings, which are liable to National Insurance, will be reduced if you are claiming expenses each week / month and this may affect the amount of benefits you receive.
                        <br><br>
                        <strong>4.5</strong> The following information explains what you’ll receive if you have a baby while employed by us.
                        <br><br>
                        <h4><strong>4.6</strong> Maternity Pay</h4>
                        <br><br>
                        <strong>4.6.1</strong> In order to receive maternity pay you must have been continuously employed by GCIPLTD Umbrella for a period of 26 weeks before your qualifying week. The qualifying week is the week 15 weeks prior to
                        the week your baby is due. If you do not fulfil this criterion then you will not be entitled to Maternity Pay.
                        <br><br>
                        <strong>4.6.2</strong> You will have to inform us of your pregnancy by the Sunday of your qualifying week, otherwise we might not be able to pay Maternity Pay. Maternity Pay will be paid for the duration of your Ordinary Maternity Leave up to 39 weeks.
                        <br><br>
                        <strong>4.6.3</strong> Payments will be: For the first six weeks of Ordinary Maternity Leave - 90% of your average earnings (based upon your average earnings in the two months immediately preceding your qualifying week). For the remaining 33 weeks of Ordinary Maternity Leave - 90% of your average weekly earnings or a standard rate set by the Department for Work and Pensions (DWP), whichever is lower.
                        <br><br>
                        <strong>4.6.4</strong> However, if you do not qualify for maternity pay, you may be entitled to Maternity Allowance. This is paid by Social Security/Jobcentre Plus. Contact your local job centre for more information.
                        <h4><strong>4.7</strong> Paternity Pay</h4>
                        
                        <strong>4.7.1</strong> In order to be eligible for paternity pay, you must have been employed by GCIPLTD Umbrella for 26 weeks or more at the Qualifying Week* and you must also fulfil the following criteria:
                        <ul>
                            <li>I. You have or expect to have responsibility for the child’s upbringing.</li>
                            <li>II. You are the biological father of the child or the mother’s husband or partner (for paternity)</li>
                            <li>III. *The qualifying week is the week 15 weeks prior to the week your baby is due. You will have to inform us of the pregnancy by the Sunday of the qualifying week, otherwise we might not be able to pay Paternity Pay.</li>
                        </ul>
                        <br>
                        <strong>4.7.2</strong> Paternity pay will be paid for either one week or two consecutive weeks (whichever you have chosen). Leave will be paid at a rate of 90% of your average weekly earnings or a standard rate set by the DWP, whichever is lower.
                        <br><br>
                        <h4><strong>4.8</strong> Parental Leave</h4>
                        
                        <strong>4.8.1</strong> Once you have worked for us for a year you can take: 13 weeks off work (in total, not per year) for each child, up to their fifth birthday (or up to five years after the placement of an adopted child) 18 weeks for each disabled child, up to the child’s 18th birthday
                        <br><br>
                        <strong>4.8.2</strong> Parental leave is unpaid. It’s different from maternity or maternity leave, which is related to the birth of a new baby and from adoption leave, which applies when an employee adopts a child.
                        </h5>
                      </div>

                      <div id="5" class="link-pad">
                        <h3 class="title">5.0 Expenses</h3>
                        <h5>
                        <strong>5.1</strong> The purpose of this guide is to give you an overview of the type of expenses that HMRC considers tax allowable. If after reading this guide you feel you will incur expenditure or
                        another type of expense not listed, please call our Umbrella Services on <strong>01322 272 859</strong> for advice. No payment of expenses will be made to Employees until a signed contract showing that the policy has been fully read and understood.
                        <br><br>
                        <strong>5.2</strong> Only allowable expenses can be claimed. An expense must be incurred wholly, necessarily and exclusively in the performance of your duties. We will check all expense claims to ensure that only allowable expenses are processed. Any claim found to be fraudulent may result in you being subject to disciplinary proceedings and you will be required to re-pay the appropriate level of tax to HMRC. Unless otherwise stated, you must send us original VAT receipts.
                        <br><br>
                        <strong>5.3</strong> In order to qualify you must fulfil the following criteria: You have not nor do you expect to work at the same site for more than 24 months. You will work at more than one site during your contracting period. Please note: the moment you believe your time at a particular site will exceed 24 months or you believe this will be the only contracting assignment you will undertake, you should stop submitting these expenses and let Umbrella Services know ASAP. All expenses relating to an assignment should be submitted prior to final payment being made so that these can be reconciled and paid with the final payment for that assignment.
                        <br><br>
                        <strong>5.4 - General Expenses:</strong> The following expenses can be claimed. Unless otherwise stated, you will need to provide original VAT receipts.
                        <br><br>
                        <strong>5.5 - Subsistence Claims: </strong>These can only be made on a day you are working and for purchases after your journey has started. Only pre-packaged items may be claimed and an original VAT receipt is required. No subsistence claim will be paid on a day where one did not claim travel expenses.
                        <br><br>
                        <strong>5.6 - Travel:</strong> Mileage &amp; travel tickets can be claimed for all business related travel. Please note that this does not include travel from home to work and back as this is classed as private mileage.
                        <br><br>
                        <strong>5.7 - Accommodation:</strong> Actual accommodation expenses can be claimed. However, no allowance will be granted for staying with Friends or Family. Rates for hotel accommodation will not be considered on any scale.
                        <br><br>
                        <strong>5.8 - Subscriptions:</strong> Professional subscriptions can be reclaimed providing: they are included on HMRC’s list of approved bodies and societies and current membership is required for your job.
                        <br><br>
                        <strong>5.9 - Training courses:</strong> These are reclaimable providing they are required for you to be able to fulfil your current assignment.
                        <br><br>
                        <strong>5.10 - Tools, equipment &amp; uniforms:</strong> If you need to purchase tools, safety equipment/wear including uniforms or other equipment that is essential to your assignment, such costs are reclaimable. Should the amount exceed £250 per year, please contact Umbrella Services.
                        <br><br>
                        <strong>5.11 - Expenses Audit Process: </strong>All expense claims are subject to audit on submission. Random spot checks will be carried out twice yearly in any financial year without the prior knowledge of the Employee.
                        <br><br>
                        <strong>5.12</strong> All expenses claims made are manually checked and verified against each date, month time and year worked.  No subsistence claim will be paid on a day where one did not claim travel expenses.
                        <br><br>
                        <strong>5.13</strong> All legitimate claims have to be signed by the relevant Line Manager
                        <br><br>
                        <strong>5.14</strong> Original Supporting documents including VAT receipts have to attached for each claim submitted
                        <br><br>
                        <strong>5.15</strong> For mileage claims a postcode check will be carried out to confirm the miles claimed are not overstated
                        <br><br>
                        <strong>5.16</strong> Approved claims will be countersigned by the Payroll Manager before being passed for payroll processing and payment
                        <br><br>
                        <strong>5.17</strong> All approved expenses have to appear on the respective Staff Member payslip
                        </h5>
                      </div>

                      <div id="6" class="link-pad">
                        <h3 class="title">6.0 Code of Conduct</h3>
                        <h5>
                        <h4><strong>6.1</strong> Standards of Behaviour</h4>
                        
                        <strong>6.1.1</strong> We expect you to present yourself in a professional and business-like manner at all times. All reasonable instructions given to you by your line manager must be carried out.
                        <br><br>
                        <h4><strong>6.2</strong> Personal Telephone Calls</h4>

                        <strong>6.2.2</strong> Whilst we recognise that there is a need to make or receive some personal telephone calls during working hours, these should be kept to a minimum. Non-work mobile phones should be switched off during office hours.
                        <br><br>
                        <h4><strong>6.3</strong> Gifts</h4>
                        <strong>6.3.3</strong> Those who are responsible for expenditure with outside suppliers should not accept gifts or other rewards from them. Any such offer should be politely declined and anything received should be politely returned. The acceptance of items such as diaries and calendars, however, is permitted.
                        <br><br>
                        <h4><strong>6.4</strong> Drink and Drugs</h4>
                        <strong>6.4.1</strong> You are not permitted to drink alcohol or to be under the influence of alcohol during working hours. Similarly, you are not permitted to be in possession of or to be under the influence of drugs, other than those specifically prescribed to you by a registered medical practitioner, during working hours.
                        <br><br>
                        <h4><strong>6.5</strong> Personal Possessions</h4>
                        <strong>6.5.1</strong> It is your responsibility to take care of your own possessions whilst at work - e.g. money, coats, bags and other valuables. We cannot accept liability for the loss of, or damage to, your belongings whilst you are at work.
                        <br><br>
                        <h4><strong>6.6</strong> Personal Details</h4>
                        <strong>6.6.1</strong> Please tell us as soon as possible of any changes in your personal details (e.g. name, home address, telephone number, next of kin, etc.) so that we can keep our personnel records up to
                        date.
                        <br><br>
                        <h4><strong>6.7</strong> Away from Work</h4>
                        <strong>6.7.1</strong> People will judge us by your behaviour. This is true even when you are not at work. Remember that wherever you are - e.g. travelling to and from work, meeting people socially, staying at a hotel, etc.- potential customers may be forming an impression about our Company from your behaviour. Any conversations you may have about work may also be overheard so please remember to remain professional at all times.
                        <br><br>
                        <h4><strong>6.8</strong> No Smoking Policy</h4>
                        <strong>6.8.1</strong> We value the health, safety and welfare of our employees. We recognise that smoking is damaging to health and harmful to the environment. This policy aims to protect all employees, customers and any visitors from exposure to second hand smoke and to comply with the Health Act 2006. Under the Health Act 2006 smoking in all enclosed premises and substantially enclosed
                        premises is outlawed. This includes company vehicles. This policy applies to all employees, customers and any visitors.
                        <br><br>
                        <h4><strong>6.9</strong> IT Policy - General Guidelines</h4>
                        <strong>6.9.1</strong> Because of licensing regulations you should not install software on client machines, unless permitted to do so. In most cases licensing agreements will not allow for any software to be
                        copied by you or taken home. Records and/or software instructions must not be altered, unless you are authorised to do so.
                        <br><br>
                        <strong>6.9.2</strong> You must ensure good practice to prevent the introduction of a virus to any of the systems you are authorised to use.
                        <br><br>
                        <strong>6.9.3</strong> You should protect your personal password if you have one, and you must not use anyone else’s ID to access systems that are not normally available to you. Employees who are issued with items of I.T. equipment are required to return them in good condition and in good working order at the end of the project for which they were issued or upon leaving The Focused Group’s employment.
                        <br><br>
                        <h4><strong>6.10</strong> Email</h4>
                        <strong>6.10.1</strong> From time to time you may be required to use a client’s email system. As an important business tool use of email for non-business purposes should be kept to a minimum. You must not send emails containing material which is: pornographic or sexual; defamatory or offensive; in any way illegal; or which may in any way bring the Focused Consulting into disrepute. Sending material of this kind is likely to be treated as Gross Misconduct.
                        <br><br>
                        <h4><strong>6.11</strong> Internet</h4>
                        <strong>6.11.1</strong> You must not use the Internet to access content of a pornographic or sexual nature. You must not access social networking sites during work hours. Doing so will be regarded as Gross Misconduct. You must not download unauthorised software from the Internet whilst on a client site.
                        <br><br>
                        <h4><strong>6.12</strong> IT Security</h4>
                        <strong>6.12.1</strong> In order to protect client’s IT Systems from the threat of computer viruses, we ask that you do not use instant messaging and peer to peer file sharing programs. This includes programmes such as
                        MSN Messenger, AOL Instant Messenger, Yahoo Messenger or peer to peer file sharing programs such as Kazaa and Edonkey.
                        <br><br>
                        <h4><strong>6.13</strong> Training and Development</h4>
                        <strong>6.13.1</strong> We are committed to providing the training and coaching that will enable employees to carry out their jobs to the highest standard and that will encourage them to develop their potential. A selection of training can be made available in most major cities.
                        </h5>
                      </div>

                      <div id="7" class="link-pad">
                        <h3 class="title">7.0 Disciplinary Procedure</h3>
                        <h5>
                        <h4><strong>7.1</strong> Disciplinary Policy &amp; Procedure</h4>
                        <strong>7.1.1</strong> Our disciplinary procedure is designed to ensure that you are treated fairly and to clarify the kinds of acts, omissions or behaviours which could result in you being disciplined, suspended or dismissed. If you are required to attend a disciplinary interview, then in all instances you have the right to be accompanied by a work colleague or a Trade Union representative.
                        <br><br>
                        <h4><strong>7.2</strong> Disciplinary Offences</h4>
                        <strong>7.2.1</strong> In general, there are three areas that could result in disciplinary action being taken:
                        <ul>
                          <li>I. Unsatisfactory performance - if your work falls below agreed standards or if you fail to achieve agreed objectives (even if you are doing your best).</li>
                          <li>II. Misconduct - this is one (or more) action, omission or behaviour that is deemed to be unacceptable - e.g. inaccurate claim of expenses, irregular attendance, poor timekeeping, disruptive or rude behaviour, refusal to carry out reasonable requests, disobeying general rules, etc.</li>
                          <li>III. Gross Misconduct - this is one (or more) action, omission or behaviour that is deemed to be so serious that it may result in summary dismissal (i.e. without notice).</li>
                          <br>
                          <strong>The following examples may constitute Gross Misconduct:</strong>
                          <li>A. dishonesty;</li>
                          <li>B. theft of property or funds from the Company, customers or Employees;</li>
                          <li>C. being unable to carry out your job because you are under the influence of drink or illegal drugs;</li>
                          <li>D. possessing illegal drugs;</li>
                          <li>E. physical violence; malicious damage to property;</li>
                          <li>F. disobeying, or failing to abide by, any or all of our policies or procedures;</li>
                          <li>G. failure to abide by relevant codes of practice or legislation; fraud/falsifying
                        documentation;</li>
                          <li>H. bringing the Company name into disrepute;</li>
                          <li>I. discriminating against anyone on grounds of age, sex, marital status, sexual orientation,
                        ethnic origin, race, religious background, disability or dependants considerations;</li>
                          <li>J. bullying, harassment or victimisation. entering into contracts on behalf of the Company
                        without proper authority</li>
                        </ul>
                        <strong>7.2.2</strong> The above list is not exhaustive, and is intended purely as a guide.
                        </h5>
                      </div>

                      <div id="8" class="link-pad">
                        <h3 class="title">8.0 Grievance Procedure</h3>
                        <h5>
                        <h4><strong>8.1</strong> Grievance Policy &amp; Procedure</h4>
                        <strong>8.1.1</strong> We try to ensure that everyone who works for us is treated impartially and fairly. However, sometimes things can happen which appear unfair to you. To allow for such possibilities, we have a formal Grievance Procedure which you should use if you feel it necessary. This policy does not prevent you from dealing with your grievance informally. Indeed, in many cases this may be the
                        most constructive way of resolving an issue or problem. However, if you have tried to resolve your grievance informally and are not satisfied or if you do not wish to take the informal approach then
                        you should follow this procedure.
                        <br><br>
                        <h4><strong>8.2</strong> How the Grievance Procedure works</h4>
                        <strong>8.2.1</strong> You can choose to present your case in writing or in person or on the telephone. If you present your case in person you have the right to be accompanied to the meeting by a work colleague
                        or trade union representative. Whichever method you choose, you must make sure that your Director is aware that you wish to use the Grievance Procedure.
                        <br><br>
                        <h4><strong>8.3</strong> Stage 1</h4>
                        <strong>8.3.1</strong> Inform your Director in writing that you wish to invoke the grievance procedure and provide written details of your grievance.
                        <br><br>
                        <strong>8.3.2</strong> Within 5 working days of receiving your grievance your Director will write to you to inform you how the grievance will be managed and the person who will hear your grievance. This will either be: Your immediate manager;
                        <ul>
                          <li>A. or If your grievance is directed at something your immediate manager did or did not do,</li>
                          <li>B. your manager’s immediate manager;</li>
                          <li>C. or A suitable alternative employee (selected by your Director) if your Director deem neither of the above to be appropriate in the circumstances.</li>
                        </ul>
                        <br>
                        <strong>8.3.3</strong> If you have raised a grievance against another employee he/she will be informed of this by your Director and provided with a copy of your written grievance complaint. You will be invited to attend a meeting to present your grievance in person. You must notify your Director at least 3 days in advance of the meeting of any witnesses you intend to call to give evidence at the hearing. Alternatively, if you so wish, you can present your grievance in writing or by telephone.
                        Every reasonable effort will be made to come to a conclusion regarding your grievance as quickly as possible, subject to the availability of the relevant individuals, the need to conduct a
                        thorough investigation and the right of the person(s) against whom any allegations are directed to consider and respond to them.
                        <br><br>
                        <h4><strong>8.4</strong> Stage 2</h4>
                        <strong>8.4.1</strong> If you are unhappy with the Stage 1 grievance decision(s) (or part of it) you have the right to appeal. You must notify your Director in writing of the grievance decision(s) you wish to appeal
                        within 5 working days of receiving the decision(s).
                        <br><br>
                        <strong>8.4.2</strong> Within 5 working days of receiving your notice of appeal your Director will write to you to inform you how your grievance appeal will be managed and the person who will hear your
                        appeal. Your appeal will be heard by a more senior Co-Member to the Stage 1 decision maker or a suitable alternative as notified to you by your Director.
                        <br><br>
                        <strong>8.4.3</strong> If you have raised a grievance against another employee he/she will be informed of your appeal by your Director. You will be invited to a meeting to present your appeal. You must notify your Director at least 3 days in advance of the meeting of any witnesses you intend to call to give evidence at the meeting. Alternatively, if you so wish, you can choose to present your grievance appeal in writing or by telephone. Every reasonable effort will be made to come to a conclusion regarding your grievance appeal as quickly as possible, subject to the availability of the relevant individuals, the need to conduct a thorough investigation and the right of the person(s) against whom any allegations are directed to consider and respond to them.
                        <br><br>
                        <strong>8.4.4</strong> The evidence given and the decision made at Stage 1 of the grievance procedure may be considered at the discretion of the person hearing the appeal. When your Director write to you
                        under point 2 above, they will state whether or not this discretion is being exercised. All parties are free to introduce fresh evidence on appeal. Any new evidence introduced must be provided to your Director no later than 3 days before the meeting. The decision of the person hearing your grievance appeal is final.
                        <br><br>
                        <h4><strong>8.5</strong> Modified Procedure</h4>
                        <strong>8.5.1</strong> If you raise a grievance after you have left our employment you agree to have your grievance dealt with by a modified procedure. Under the modified procedure you are required to provide written details of your grievance to your Director. Your written complaint will then be passed to an appropriate person who will respond to your grievance in writing. This decision will be final.
                        Whistleblowing
                        <br><br>
                        <strong>8.5.2</strong> The Public Interest Disclosure Act 1998 offers protection against victimisation or dismissal for workers who “blow the whistle” on criminal behaviour or other wrongdoing. We encourage our
                        employees to bring their concerns to the attention of management. We will not victimise or take disciplinary action against employees for whistle blowing, and any such treatment would be considered Gross Misconduct and may be dealt with in accordance with the Disciplinary Procedure.
                        <br><br>
                        <h4><strong>8.6</strong> Actions covered by this policy</h4>
                        <strong>8.6.1</strong> This policy has been designed to cover situations where employees have a reasonable belief that one of the following has taken, is or is likely to be taking place:
                        <ul>
                          <li>A. A criminal offence and/or dishonesty (for example: theft of Company property, theft or fraudulent claiming of public money, falsification of expense or bonus claims)</li>
                          <li>B. A failure to comply with a legal obligation (this includes both statutory obligations and acts which are deliberately in breach of contractual obligations)</li>
                          <li>C. Endangering an individual’s health or safety</li>
                          <li>D. A miscarriage of justice</li>
                          <li>E. Material breach of procedures</li>
                          <li>F. This list is intended as a guide, and is not exhaustive.</li>
                        </ul>
                        <h4><strong>What should you do if you come across this kind of act?</strong></h4>
                        If you become aware of any such act you should immediately do one of the following:
                        <ul>
                          <li>A. bring it to the attention of your manager;</li>
                          <li>B. or (if you believe that your manager might be involved) bring it to the attention of your manager’s manager; or bring it to the attention of your Director.</li>
                        </ul>
                        <br>
                        <h4><strong>8.7</strong> Confidentiality</h4>
                        <strong>8.7.1</strong> Your identity will be protected wherever possible. We will take responsibility for taking action forward, and will keep you informed, where appropriate.
                        </h5>
                      </div>

                      <div id="9" class="link-pad">
                        <h3 class="title"><strong>9.0</strong> Pension</h3>
                        <h5>
                        <strong>9.1</strong> The Company will at all times comply with the obligations placed upon it for the provision of
                        pensions to employees and will notify you in writing of the details of any scheme from time to time in force.
                        <br><br>
                        <strong>9.2</strong> Currently we are opted to The National Employment Savings Trust (NEST).
                        <br><br>
                        <strong>9.3</strong> All staff are automatically enrolled into the NEST pension scheme under the governments auto enrollment rules and anyone who does not want to be part of the scheme can opt out by putting their request in writing to NEST directly or by emailing us at <a href="mailto:info@gcipltd.com">info@gcipltd.com</a>
                        <br><br>
                        <strong>9.4</strong> NEST is a defined contribution workplace pension scheme in the United Kingdom. It was set up to facilitate automatic enrollment as part of the government’s workplace pension reforms under the Pensions Act 2008.
                        <br><br>
                        <strong>9.5</strong> For more information please visit <a href="http://www.nestpensions.org.uk/schemeweb/nest/aboutnest.html" target="_blank"><strong>NEST</strong></a> or contact Umbrella Services on <strong>01322 272 859</strong>
                        </h5>
                      </div>

                      <div id="10" class="link-pad">
                        <h3 class="title">10.0 Data Protection Notice</h3>
                        <h5>
                        <h4><strong>10.1</strong> Data Protection Policy</h4>
                        <strong>10.2</strong> We respect the Personal Data of all individuals against the backdrop of its Data Protection Policy. Breaching this policy may result in disciplinary action. If you have any concerns regarding
                        the way we are holding or processing your Personal Data you must immediately contact the Data Protection Officer (Company Secretary).
                        <br><br>
                        <strong>10.3</strong> Our GDPR Data Protection - Privacy Notice is available <a href="/data-protection-notice"><strong>HERE</strong></a>
                        </h5>
                      </div>

                      <div id="11" class="link-pad">
                        <h3 class="title">11.0 Insurance Cover</h3>
                        <h5>
                        <strong>11.1</strong> As an employee of GCIPLTD Umbrella you are covered by the following insurances:
                        <ul>
                          <li>I. Employers Liability up to £10 Million</li>
                          <li>II. Public Liability up to £5 Million</li>
                          <li>III. Professional Indemnity up to £2 Million</li>
                        </ul>
                        <br>
                        <strong>11.2</strong> If you require further information about the insurance cover please contact Umbrella Services on <strong>01322 272 859.</strong>
                        </h5>
                      </div>

                      <div id="12" class="link-pad">
                        <h3 class="title">12.0 Criminal Finance Act – Part 3 Procedure</h3>
                        <h5>
                        GCIPLTD Umbrella is committed to preventing tax evasion or financial crime. We endeavour to achieve this by ensuring that all expenses are audited and taxed at the appropriate levels, no hidden charges, payments or disguised remuneration leading to undeclared income being withheld from HM Revenue &amp; Customs.
                        <br><br>
                        All payments to Staff will be to UK personal bank accounts and no other. Any deviation from this policy will be a disciplinary offence which may lead to dismissal.
                        <br><br>
                        GCIPLTD Umbrella will from time to time provide staff training on recognising and preventing financial crime and ensure a safe and anonymised whistle-blowing procedure is maintained. We will conduct regular reviews for prevention procedures and changing them where required.
                        <br><br>
                        Where Staff Member(s) have any concerns, they should report their concerns straightaway to their Line Manager and/or Senior Manager or Professional Body or the Police.
                        </h5>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  import { Card, Button, FormGroupInput, Tabs, TabPane } from '@/components';
  import {Table, TableColumn, Tooltip} from 'element-ui';

  export default {
    components: {
      Card,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      Tabs,
      TabPane,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput
    },
    data() {
      return {
      }
    }
  }
</script>
<style>
.tab {
  padding-left: 30px;
}
.link-pad {
  padding-top: 40px;
}

.nav-list {
    justify-content: center;
    text-align: left;
    display: grid;
    font-size: 2em;
}
</style>
