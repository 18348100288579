<template>
    <div class="wrapper">
        <div class="page-header page-header-small">
            <parallax class="page-header-image section-image-primary"
                 style="background-image: url('img/banner7.jpg')">
            </parallax>
            <div class="content-center">
                <h1 class="title"></h1>
            </div>
        </div>

        <div class="section-brand-2">
            <div class="container">
                <div class="row">
                    <div class="col-md-10 text-left">
                        <h2 class="title">You’re almost there</h2>

                        <h5>As a new employee of GCIPLTD Umbrella yours and our responsibilities are outlined
                            in your overarching contract of employment along with the staff handbook
                            which includes the basis upon which you may claim allowable expenses
                            or take out a grievance.
                            <br><br>
                            So please read through the contracts below and then at the bottom of
                            this page tick and sign to confirm you have read and agree with our policies.
                        </h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-brand-2">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-lg-4">
                        <card color="black">
                            <h4 class="card-title">
                                Staff Handbook
                            </h4>
                            <p class="card-description">
                                The staff handbook includes the basis upon which you may
                                claim allowable expenses or take out a grievance, along with
                                all other polices and procedures that affect you.
                            </p><br>
                            <div class="card-footer text-center">
                                <a href="/staff-handbook"
                                class="btn btn-primary btn-lg btn-round">
                                    View Staff Handbook
                                </a>
                            </div>
                        </card>
                    </div>

                    <div class="col-md-6 col-lg-4">
                        <card color="black">
                            <h4 class="card-title">
                                Contract of Employment
                            </h4>
                            <p class="card-description">
                                The contract of employment sets out yours and our
                                responsibilities towards each other under this employment.
                            </p><br><br><br>
                            <div class="card-footer text-center">
                                <a href="/contract-of-employment"
                                class="btn btn-primary btn-lg btn-round">
                                    View Contract
                                </a>
                            </div>
                        </card>
                    </div>

                    <div class="col-md-6 col-lg-4">
                        <card color="black">
                            <h4 class="card-title">
                                Working Time Directive Policy
                            </h4>
                            <p class="card-description">
                                We are committed to the health & safety of our employees therefore,
                                if you want to work more than 48 hours per week you must opt-out of the Working Time Directive.
                            </p>
                            <div class="card-footer text-center">
                                <a href="/working-time-directive-policy"
                                class="btn btn-primary btn-lg btn-round">
                                    View Policy
                                </a>
                            </div>
                        </card>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-brand-2">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-lg-4">
                        <card color="black">
                            <h4 class="card-title">
                                Pension Scheme
                            </h4>
                            <p class="card-description">
                                GCIPLTD Umbrella believes its employees should plan for the
                                future and as such we have implemented a pension
                                scheme which employees are automatically enrolled
                                into. Once you have already been enrolled, NEST will write to you and
                                if you do not wish to pay into the pension scheme you must opt-out direct with NEST.
                            </p>
                            <div class="card-footer text-center">
                                <a href="https://www.nestpensions.org.uk/schemeweb/nest/aboutnest.html" target="_blank"
                                class="btn btn-primary btn-lg btn-round">
                                    View Policy
                                </a>
                            </div>
                        </card>
                    </div>

                    <div class="col-md-6 col-lg-4">
                        <card color="black">
                            <h4 class="card-title">
                                Data Protection Notice
                            </h4>
                            <p class="card-description">
                                This privacy notice describes how we collect and use
                                information about you during and after your working
                                relationship with us, in accordance with the GDPR.
                            </p><br><br>
                            <div class="card-footer text-center">
                                <a href="/data-protection-notice" class="btn btn-primary btn-lg btn-round">
                                    Read Notice
                                </a>
                            </div>
                        </card>
                    </div>

                    <div class="col-md-6 col-lg-4">
                        <card color="black">
                            <h4 class="card-title">
                                Expense Guidelines
                            </h4>
                            <p class="card-description">
                                The purpose of this guide is to give you an overview
                                of the type of expenses that HMRC considers tax allowable.
                            </p><br><br><br>
                            <div class="card-footer text-center">
                                <a href="/staff-handbook/#5" class="btn btn-primary btn-lg btn-round">
                                    Read Guidelines
                                </a>
                            </div>
                        </card>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-brand-2">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-left">
                        <h2 class="title">Confirmation Form</h2>
                        <h5>Please tick the following to confirm*</h5>
                        <n-checkbox :error="handbookErrors" v-model="form.agreements.handbook">
                            I have read and understood the policies and procedures set within the Staff Handbook
                        </n-checkbox>
                        <n-checkbox :error="contractErrors" v-model="form.agreements.contract">
                            I have read and understood the policies and procedures set within the Contract of Employment
                        </n-checkbox>
                        <n-checkbox :error="dpNoticeErrors" v-model="form.agreements.dpNotice">
                            I have read and understood the Data Protection Notice
                        </n-checkbox>
                        <n-checkbox :error="expenseErrors" v-model="form.agreements.expense">
                            I have read and understood the Expense Guidelines
                        </n-checkbox>

                        <br><br>

                        <h5>Have you worked at the same place (site) in the last 3 months?*</h5>
                        <n-radio v-model="form.checks.sameSite" label="yes">
                            I have worked at the same place (site) for the last 3 months
                        </n-radio>
                        <n-radio v-model="form.checks.sameSite" label="no">
                            I have NOT worked at the same place (site) for the last 3 months
                        </n-radio>
                        <div class="text-danger invalid-feedback mt-2" style="display: block;" v-if="!form.checks.sameSite && $v.$dirty">
                          {{ errors.reqMsg }}
                        </div>

                        <br><br>

                        <h5>Pension Scheme*</h5>
                        <n-checkbox :error="pensionErrors" v-model="form.checks.pension">
                            I understand I will be automatically enrolled into the NEST pension scheme
                        </n-checkbox>
                        <!--n-radio v-model="form.checks.pension" label="opt-out">
                            I wish to opt-out of the Pension Scheme
                        </n-radio>
                        <n-radio v-model="form.checks.pension" label="opt-in">
                            I DO NOT wish to opt-out of the Pension Scheme
                        </n-radio>
                        <div class="text-danger invalid-feedback mt-2" style="display: block;" v-if="!form.checks.pension && $v.$dirty">
                          {{ errors.reqMsg }}
                        </div-->

                        <br><br>

                        <h5>Working Time Directive Policy*</h5>
                        <n-radio v-model="form.checks.workTimePolicy" label="opt-out">
                            I wish to opt-out of the working time directive
                        </n-radio>
                        <n-radio v-model="form.checks.workTimePolicy" label="opt-in">
                            I DO NOT wish to opt-out of the working time directive
                        </n-radio>
                        <div class="text-danger invalid-feedback mt-2" style="display: block;" v-if="!form.checks.workTimePolicy && $v.$dirty">
                          {{ errors.reqMsg }}
                        </div>
                    </div>

                    <div class="col-lg-12 ml-auto mr-auto col-md-12">
                        <br><br>
                    </div>

                    <div class="col-lg-6 mr-auto col-md-6">
                        <label>First Name *</label>
                        <fg-input :error="firstNameErrors" v-model="form.personal.firstName" class="input-lg"/>
                    </div>
                    <div class="col-lg-6 mr-auto col-md-6">
                        <label>Last Name *</label>
                        <fg-input :error="lastNameErrors" v-model="form.personal.lastName" class="input-lg"/>
                    </div>

                    <div class="col-lg-6 ml-auto mr-auto col-md-6">
                        <label>Address Line 1 *</label>
                        <fg-input :error="addressErrors1" v-model="form.personal.address1" class="input-lg"/>
                    </div>

                    <div class="col-lg-6 mr-auto col-md-6">
                        <label>Address Line 2</label>
                        <fg-input v-model="form.personal.address2" class="input-lg"/>
                    </div>

                    <div class="col-lg-4 ml-auto mr-auto col-md-12">
                        <label>City *</label>
                        <fg-input :error="cityErrors" v-model="form.personal.city" class="input-lg"/>
                    </div>

                    <div class="col-lg-4 ml-auto mr-auto col-md-12">
                        <label>County *</label>
                        <fg-input :error="countyErrors" v-model="form.personal.county" class="input-lg"/>
                    </div>

                    <div class="col-lg-4 ml-auto mr-auto col-md-12">
                        <label>Postcode *</label>
                        <fg-input :error="postcodeErrors" v-model="form.personal.postcode" class="input-lg"/>
                    </div>

                    <div class="col-lg-6 ml-auto mr-auto col-md-12">
                        <label>Occupation *</label>
                        <fg-input :error="occupationErrors" v-model="form.personal.occupation" class="input-lg"/>
                    </div>

                    <div class="col-lg-6 ml-auto mr-auto col-md-12">
                        <label>Email Address *</label>
                        <fg-input :error="emailErrors" v-model="form.personal.email" class="input-lg" placeholder="address@email.com"/>
                    </div>

                    <div class="col-lg-12 ml-auto mr-auto col-md-12">
                        <br><br>
                    </div>

                    <div class="col-lg-6 text-center ml-auto mr-auto col-md-6">
                      <div class="send-button" @click="submit()"> <!-- @click="submit()" -->
                        <n-button type="primary" round :disabled="disabled" block size="lg">Next</n-button>
                        <div :class="statusClass" style="display: block;" v-if="formSent">
                            {{ resMsg }}
                        </div>
                        <div class="text-danger invalid-feedback mt-2" style="display: block;" v-if="$v.form.$anyError">
                          {{ errors.generalMsg }}
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <modal :show.sync="modals.classic" headerClasses="justify-content-center">
            <preview v-if="formSent" :form="form"></preview>
        </modal>
    </div>
</template>
<script>
  import { Card, Modal, Checkbox, Button, Radio, FormGroupInput } from '@/components';
  import formSubmission from '../../services/Form'
  import { required, email, sameAs } from 'vuelidate/lib/validators'
  import Preview from '../Preview.vue'
  import axios from 'axios'

  export default {
    components: {
      Card,
      Modal,
      'preview': Preview,
      [Radio.name]: Radio,
      [Button.name]: Button,
      [Checkbox.name]: Checkbox,
      [FormGroupInput.name]: FormGroupInput
    },
    data () {
        return {
          modals: {
            classic: false
          },

          form: {
              agreements: {
                  handbook: false,
                  contract: false,
                  dpNotice: false,
                  expense: false
              },
              checks: {
                  sameSite: '',
                  pension: false,
                  workTimePolicy: ''
              },
              personal: {
                  firstName: '',
                  lastName: '',
                  address1: '',
                  address2: '',
                  city: '',
                  county: '',
                  postcode: '',
                  email: '',
                  occupation: ''
              },
              formDate: null,
              sig: '',
              template: 'contract',
              userIPAddress: ''
          },

          errors: {
            generalMsg: 'One or more fields have an error. Please check and try again.',
            reqMsg: 'This field is required',
            numMsg: 'Enter a number',
            decMsg: 'Enter a decimal number',
            emailMsg: 'Enter valid email address'
          },
          resMsg: '',
          statusClass: '',
          formSent: false,
          disabled: false
        }
    },

    validations: {
      form: {
        agreements: {
          handbook: { sameAs: sameAs( () => true ) },
          contract: { sameAs: sameAs( () => true ) },
          dpNotice: { sameAs: sameAs( () => true ) },
          expense: { sameAs: sameAs( () => true ) }
        },
        checks: {
          sameSite: { required },
          pension: { sameAs: sameAs( () => true ) },
          workTimePolicy: { required }
        },
        personal: {
          firstName: { required },
          lastName: { required },
          address1: { required },
          city: { required },
          county: { required },
          postcode: { required },
          email: { required, email },
          occupation: { required }
        }
      }
    },

    computed: {
      handbookErrors() {
        if (this.$v.form.agreements.handbook.$error) {
          if (!this.$v.form.agreements.handbook.sameAs) {
            return this.errors.reqMsg
          } else {
            return ''
          }
        }
      },

      contractErrors() {
        if (this.$v.form.agreements.contract.$error) {
          if (!this.$v.form.agreements.contract.sameAs) {
            return this.errors.reqMsg
          } else {
            return ''
          }
        }
      },

      dpNoticeErrors() {
        if (this.$v.form.agreements.dpNotice.$error) {
          if (!this.$v.form.agreements.dpNotice.sameAs) {
            return this.errors.reqMsg
          } else {
            return ''
          }
        }
      },

      expenseErrors() {
        if (this.$v.form.agreements.expense.$error) {
          if (!this.$v.form.agreements.expense.sameAs) {
            return this.errors.reqMsg
          } else {
            return ''
          }
        }
      },

      pensionErrors() {
        if (this.$v.form.checks.pension.$error) {
          if (!this.$v.form.checks.pension.sameAs) {
            return this.errors.reqMsg
          } else {
            return ''
          }
        }
      },

      firstNameErrors() {
        if (this.$v.form.personal.firstName.$error) {
          if (!this.$v.form.personal.firstName.required) {
            return this.errors.reqMsg
          } else {
            return ''
          }
        }
      },

      lastNameErrors() {
        if (this.$v.form.personal.lastName.$error) {
          if (!this.$v.form.personal.lastName.required) {
            return this.errors.reqMsg
          } else {
            return ''
          }
        }
      },

      emailErrors() {
        if (this.$v.form.personal.email.$error) {
          if (!this.$v.form.personal.email.required) {
            return this.errors.reqMsg
          } else if (!this.$v.form.personal.email.email) {
            return this.errors.emailMsg
          } else {
            return ''
          }
        }
      },

      addressErrors1() {
        if (this.$v.form.personal.address1.$error) {
          if (!this.$v.form.personal.address1.required) {
            return this.errors.reqMsg
          } else {
            return ''
          }
        }
      },

      cityErrors() {
        if (this.$v.form.personal.city.$error) {
          if (!this.$v.form.personal.city.required) {
            return this.errors.reqMsg
          } else {
            return ''
          }
        }
      },

      countyErrors() {
        if (this.$v.form.personal.county.$error) {
          if (!this.$v.form.personal.county.required) {
            return this.errors.reqMsg
          } else {
            return ''
          }
        }
      },

      postcodeErrors() {
        if (this.$v.form.personal.postcode.$error) {
          if (!this.$v.form.personal.postcode.required) {
            return this.errors.reqMsg
          } else {
            return ''
          }
        }
      },

      occupationErrors() {
        if (this.$v.form.personal.occupation.$error) {
          if (!this.$v.form.personal.occupation.required) {
            return this.errors.reqMsg
          } else {
            return ''
          }
        }
      }
    },

    methods: {
      async getIP() {
        const response = await axios.get(process.env.VUE_APP_LINK_IP)
        return response.data.ip
      },
      async submit () {
        try {
          this.$v.$touch()
          if(!this.$v.form.$anyError && this.formSent === false) {
            this.formSent = true
            this.disabled = true

            if (this.form.checks.pension === 'opt-out') {
              this.form.checks.pension = ''
            } else if (this.form.checks.pension === 'opt-in') {
              this.form.checks.pension = ' DO NOT'
            }

            if (this.form.checks.workTimePolicy === 'opt-out') {
              this.form.checks.workTimePolicy = ''
            } else {
              this.form.checks.workTimePolicy = ' DO NOT'
            }

            if (this.form.checks.sameSite === 'no') {
              this.form.checks.sameSite = ' NOT'
            } else {
              this.form.checks.sameSite = ''
            }

            this.modals.classic = true

            /*grecaptcha.ready(function() {
              grecaptcha.execute(SITE_KEY, {action: 'submit'}).then(async function(token) {
              this.form.token = token

              const response = await formSubmission.create(this.form)
              const msg = response.data

              if (msg.includes('Submit')) {
                  this.statusClass = 'text-success mt-2'
                  this.resMsg = msg

                  this.$router.push({ name: 'form-complete' })
              } else {
                  this.statusClass = 'text-danger invalid-feedback mt-2'
                  this.resMsg = msg
              }
              }.bind(this))
            }.bind(this))
          }*/
          }
        } catch(err) {
          this.statusClass = 'text-danger invalid-feedback mt-2'
          this.resMsg = "Something Went Wrong, try again later!"
          this.disabled = true
          console.log(err)
        }
      }
    },
    async mounted () {
      this.form.userIPAddress = await this.getIP()
    }
  }
</script>
<style>
</style>
