var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper landing-page"},[_c('div',{staticClass:"page-header page-header-small"},[_c('parallax',{staticClass:"page-header-image",staticStyle:{"background-image":"url('img/banner3.jpg')"}}),_vm._m(0)],1),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-center"},[_c('h1',{staticClass:"title"},[_vm._v("Our Process")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mr-auto text-left"},[_c('h2',{staticClass:"title"},[_vm._v("Sign Up")]),_c('h5',[_vm._v("Signing up with GCIPLTD Umbrella is the first step to making your life easier. Simply fill out our online application which takes about 10 minutes to complete. All the information you provide is necessary for us to be able to process your payroll. So go ahead fill it out and experience our exceptional service. ")]),_c('br')])]),_c('div',{staticClass:"separator separator-primary"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-brand-2"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8 ml-auto text-right"},[_c('h2',{staticClass:"title"},[_vm._v("We Process")]),_c('h5',[_vm._v("Once we receive your application we will then compile and send all the necessary compliance documents your Agency requires. Our payroll department will have you setup and ready to go, all this only takes approximately 20 minutes due to our highly streamlined process. ")]),_c('br')])]),_c('div',{staticClass:"separator separator-primary"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mr-auto text-left"},[_c('h2',{staticClass:"title"},[_vm._v("Agency Setup")]),_c('h5',[_vm._v("At this stage we simply wait, your agency will have to setup GCIPLTD Umbrella as your Umbrella Company. The setup time is dependent on the agency. ")]),_c('br')])]),_c('div',{staticClass:"separator separator-primary"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-brand-2"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8 ml-auto text-right"},[_c('h2',{staticClass:"title"},[_vm._v("Receive Remittance")]),_c('h5',[_vm._v("In order for us to make a payment to you we need two things from your agency. First is your remittance, this lets us know how much your Gross Earnings (Before all deductions) are. When we receive the remittance from your agency, we will send you an SMS text message to notify you of the amount we are expected to receive. This gives you a chance to check if the amount matches the timesheets you have submitted. ")]),_c('br')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section section-nucleo-icons"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mr-auto text-left"},[_c('h2',{staticClass:"title"},[_vm._v("Get Paid")]),_c('h5',[_vm._v("We pay you within 2 hours of receiving payment from your Agency. We make all the necessary deductions such as your Taxes, National Insurance contributions, Student Loans, Pension and our GCIPLTD Umbrella Admin Fee. You will receive another SMS text to notify you that we have made a payment into your bank account. ")]),_c('br')]),_c('div',{staticClass:"col-lg-6 col-md-12"},[_c('div',{staticClass:"icons-container"},[_c('i',{staticClass:"now-ui-icons ui-1_send"}),_c('i',{staticClass:"now-ui-icons business_money-coins"}),_c('i',{staticClass:"now-ui-icons business_bulb-63"}),_c('i',{staticClass:"now-ui-icons education_paper"}),_c('i',{staticClass:"now-ui-icons objects_umbrella-13"}),_c('i',{staticClass:"now-ui-icons emoticons_satisfied"}),_c('i',{staticClass:"now-ui-icons business_globe"}),_c('i',{staticClass:"now-ui-icons business_bank"}),_c('i',{staticClass:"now-ui-icons ui-1_bell-53"}),_c('i',{staticClass:"now-ui-icons tech_mobile"}),_c('i',{staticClass:"now-ui-icons ui-1_email-85"}),_c('i',{staticClass:"now-ui-icons business_badge"}),_c('i',{staticClass:"now-ui-icons ui-2_time-alarm"}),_c('i',{staticClass:"now-ui-icons files_paper"}),_c('i',{staticClass:"now-ui-icons business_briefcase-24"})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-brand-2"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8 ml-auto text-right"},[_c('h2',{staticClass:"title"},[_vm._v("It's Friday")]),_c('h5',[_vm._v("Time to relax. We will send payslips every Friday evening after the close of business, it is at this point we make RTI submissions to HMRC. Rest assured that your payment will be fully compliant with HMRC regulations. ")]),_c('br')])])])])
}]

export { render, staticRenderFns }