<template>
    <div class="wrapper">
        <div class="page-header">
            <parallax class="page-header-image section-image-primary"
                 style="background-image: url('img/banner6.jpg')">
            </parallax>
            <div class="content-center">
                <h1 class="title">Your {{ formType }} Has Been Submitted!</h1>
                <h4>
                    You will receive an email message from us to notify you as soon
                    as your {{ formType }} has been processed.<br>
                    If you would like to know
                    more about our process and what you can expect from our excellent
                    service, please click the link below.
                </h4>
                <div class="buttons">
                    <a href="/our-process" class="btn btn-neutral btn-primary btn-lg mr-1">
                        Our Process
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: { type: String },
    data() {
      return {
        showMenu: false,
        extraNavClasses: '',
        currentScrollValue: 0,
        formType: '',
        msg: 'l'
      }
    },

    mounted () {
        switch (this.type) {
            case 'apply-now':
                this.formType = 'Application'
                break
        
            case 'emp-options':
                this.formType = 'Contract of Employment'
                break

            case 'holiday-pay':
                this.formType = 'Holiday Pay Request'
                break
        
            default:
                this.formType = ''
                break
        }
    }
}
</script>
