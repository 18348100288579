<template>
    <div class="wrapper landing-page">
        <div class="page-header page-header-small">
            <parallax class="page-header-image"
                 style="background-image: url('img/banner3.jpg')">
            </parallax>
            <div class="content-center">
                <h1 class="title">Our Process</h1>
            </div>
        </div>

        <div class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 mr-auto text-left">
                        <h2 class="title">Sign Up</h2>
                            <h5>Signing up with GCIPLTD Umbrella is the first step
                                to making your life easier. Simply fill
                                out our online application which takes
                                about 10 minutes to complete. All the
                                information you provide is necessary
                                for us to be able to process your payroll.
                                So go ahead fill it out and experience our exceptional service. 
                            </h5>
                            <br/>
                    </div>
                </div>
                <div class="separator separator-primary"></div>
            </div>
        </div>

        <div class="section-brand-2">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto text-right">
                        <h2 class="title">We Process</h2>
                        <h5>Once we receive your application we will
                            then compile and send all the necessary
                            compliance documents your Agency requires.
                            Our payroll department will have you setup
                            and ready to go, all this only takes
                            approximately 20 minutes due to our highly
                            streamlined process.
                        </h5>
                        <br/>
                    </div>
                </div>
                <div class="separator separator-primary"></div>
            </div>
        </div>

        <div class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 mr-auto text-left">
                        <h2 class="title">Agency Setup</h2>
                            <h5>At this stage we simply wait,
                                your agency will have to setup
                                GCIPLTD Umbrella as your Umbrella Company.
                                The setup time is dependent on the agency.
                            </h5>
                            <br/>
                    </div>
                </div>
                <div class="separator separator-primary"></div>
            </div>
        </div>

        <div class="section-brand-2">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto text-right">
                        <h2 class="title">Receive Remittance</h2>
                        <h5>In order for us to make a payment to
                            you we need two things from your agency.
                            First is your remittance, this lets us
                            know how much your Gross Earnings (Before
                            all deductions) are. When we receive the
                            remittance from your agency, we will send
                            you an SMS text message to notify you of
                            the amount we are expected to receive. This
                            gives you a chance to check if the amount
                            matches the timesheets you have submitted.
                        </h5>
                        <br/>
                    </div>
                </div>
            </div>
        </div>

        <div class="section section-nucleo-icons">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 mr-auto text-left">
                        <h2 class="title">Get Paid</h2>
                        <h5>We pay you within 2 hours of receiving
                            payment from your Agency. We make all
                            the necessary deductions such as your
                            Taxes, National Insurance contributions,
                            Student Loans, Pension and our GCIPLTD Umbrella Admin Fee.
                            You will receive another SMS text to notify you
                            that we have made a payment into your bank account.
                        </h5>
                        <br/>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="icons-container">
                            <i class="now-ui-icons ui-1_send"></i>
                            <i class="now-ui-icons business_money-coins"></i>
                            <i class="now-ui-icons business_bulb-63"></i>
                            <i class="now-ui-icons education_paper"></i>
                            <i class="now-ui-icons objects_umbrella-13"></i>
                            <i class="now-ui-icons emoticons_satisfied"></i>
                            <i class="now-ui-icons business_globe"></i>
                            <i class="now-ui-icons business_bank"></i>
                            <i class="now-ui-icons ui-1_bell-53"></i>
                            <i class="now-ui-icons tech_mobile"></i>
                            <i class="now-ui-icons ui-1_email-85"></i>
                            <i class="now-ui-icons business_badge"></i>
                            <i class="now-ui-icons ui-2_time-alarm"></i>
                            <i class="now-ui-icons files_paper"></i>
                            <i class="now-ui-icons business_briefcase-24"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-brand-2">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto text-right">
                        <h2 class="title">It's Friday</h2>
                        <h5>Time to relax. We will send payslips every Friday
                            evening after the close of business, it is at this
                            point we make RTI submissions to HMRC. Rest assured
                            that your payment will be fully compliant with HMRC regulations.
                        </h5>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  //import { Card, Button, FormGroupInput, Tabs, TabPane } from '@/components';

  export default {
    name: 'landing-page',
    bodyClass: 'landing-page',
    /*components: {
      Card,
      Tabs,
      TabPane,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput
    },*/
    /*metaInfo() {
        return {
            meta: [
                // OpenGraph data
                {property: 'og:type', content: 'website'},
                {property: 'og:url', content: 'https://www.burnleysl.co.uk/'},
                {property: 'og:image', content: 'https://www.burnleysl.co.uk/icon.png'},
                {property: 'og:description', content: 'Burnley Sales & Lettings LTD was established by experienced landlords, who after many years of being dissatisfied with the service we were receiving, felt it was time to make a difference.'},
                // Google / Schema.org markup:
                {itemprop: 'description', content: 'Burnley Sales & Lettings LTD was established by experienced landlords, who after many years of being dissatisfied with the service we were receiving, felt it was time to make a difference.'},
                {itemprop: 'image', content: 'https://www.burnleysl.co.uk/icon.png'}
            ],
            link: [
                {rel: 'canonical', href: 'https://www.burnleysl.co.uk/'}
            ]
        }
    }*/
  }
</script>
<style>
</style>
