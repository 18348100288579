<template>
    <div>
        <div class="subscribe-line subscribe-line-black">
            <br>
            <div class="container">
                <div class="row">
                    <div class="col-md-4 ml-auto mr-auto">
                        <div class="image-container1">
                            <a href="https://payepass.com/" target="_blank"><img
                                v-lazy="'img/payepass.png'" alt="PayePass Accreditation"
                            ></a>
                        </div>
                    </div>
                </div>
            </div>
            <br>
        </div>

        <footer class="footer footer-big" data-background-color="black">
            <div class="container">
                <div class="content">
                    <div class="row">
                        <div class="col-md-4">
                            <h5>Company Details</h5>
                            <p>
                                8 Conqueror Court<br>
                                Staplehurst Road<br>
                                Sittingbourne<br>
                                Kent<br>
                                ME10 5BH<br>
                                Company Reg: 05173795<br>
                                VAT Reg Number: 260341145<br>
                                Phone Number: 01322 272 859<br>
                                Email: info@gcipltd.com
                            </p>
                        </div>
                        
                        <div class="col-md-4">
                            <h5>Quick Links</h5>
                            <ul class="links-vertical">
                                <li>
                                    <a href="/#about-us">
                                        About Us
                                    </a>
                                </li>
                                <li>
                                    <a href="/apply-now">
                                        Application Form
                                    </a>
                                </li>
                                <!--li>
                                    <a href="/blog">
                                        Blog
                                    </a>
                                </li-->
                                <li>
                                    <a href="/our-process">
                                        Our Process
                                    </a>
                                </li>
                                <li>
                                    <a href="/#contact-us">
                                        Contact Us
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="col-md-4">
                            <h5>Policies</h5>
                            <ul class="links-vertical">
                                <li>
                                    <a href="/pay-gap-statement">
                                        UK Gender Pay Gap Statement
                                    </a>
                                </li>
                                <li>
                                    <a href="/slavery-statement">
                                        UK Modern Slavery Statement
                                    </a>
                                </li>
                                <li>
                                    <a href="/privacy-policy">
                                        Privacy Policy
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr/>
                <div class="copyright">
                    Copyright &copy;
                    {{year}}
                    Site by <a href="https://www.eclipse-insights.com/" target="_blank">Eclipse Insights</a>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
  export default {
    props: {
      backgroundColor: String,
      type: String
    },
    data(){
      return {
        year: new Date().getFullYear()
      }
    }
  }
</script>
<style>
</style>
