<template>
    <div class="wrapper">
        <div class="page-header page-header-small">
            <parallax class="page-header-image section-image-primary"
                 style="background-image: url('img/bg27.jpg')">
            </parallax>
            <div class="content-center">
                <h1 class="title">Contract of Employment</h1>
                <h3>The contract of employment sets out yours and our responsibilities towards each other under this employment</h3>
            </div>
        </div>

        <div class="section-brand-2">
            <div class="container">
                <div class="row">
                    <div class="col-md-10 text-left">
                        <h3>1. DATE EMPLOYMENT COMMENCED</h3>
                        <h5>1.1 – Your period of continuous employment with the Company commences on the date on which you first begin an Assignment under this contract or on the date on which you sign this contract, whichever is the later.</h5>
                        <br><br>
                        <h5>1.2 - The employment will only commence after the satisfactory submission and verification of the right to
                            work documents as outlined on the UK Home Office website as outlined:
                            <a href="https://www.gov.uk/government/publications/right-to-work-checklist" target="_blank">Here</a>
                        </h5>
                        <br><br>

                        <h3>2. PROBATIONARY PERIOD</h3>
                        <h5>2.1 – There is no probationary period which applies to your employment.</h5>
                        <br><br>

                        <h3>3. JOB TITLE, DUTIES AND ASSIGNMENT</h3>
                        <h5>3.1 – The Company will employ you in the capacity of
                        <br><br>3.2 – The Company will from time to time assign you to provide your services to third parties (“clients”) and any period during which your services are so provided shall be an “Assignment”.
                        <br><br>3.3 – The relevant terms of each Assignment (by way of example but without limitation: start date, end date, remuneration, commission, place of work, duties) are subject to agreement between you and the Company on a case by case basis and will be recorded in writing in the form appended to this contract (“Assignment Schedule”) and signed by both parties prior to the commencement of each Assignment.
                        <br><br>3.4 – The oﬀer of Assignments is entirely within the discretion of the Company and there is no guarantee that any, or any minimum number of Assignments will be oﬀered, or that any Assignment oﬀered will provide any minimum number of hours’ work. You must not unreasonably decline any Assignment oﬀered. If, in the reasonable opinion of the Company, you have unreasonably rejected on at least three occasions Assignments oﬀered, your employment may be terminated in accordance with this contract.
                        <br><br>3.5 – The Company reserves the right to terminate any Assignment on one week’s notice and shall not be liable to pay you any remuneration or compensation in respect of any period following such termination and prior to the commencement of any further Assignment.
                        <br><br>3.6 – 24 hours’ notice to the Company is required from you before leaving an Assignment. Failure to do so will be treated as a disciplinary matter and may result in the termination of your employment without notice or payment in lieu of notice.
                        <br><br>3.7 – Where an Assignment is coming to an end, the Company will seek to assist you with ﬁnding a new assignment via the agencies we already have a relationship with.
                        <br><br>3.8 – Mutuality of Obligation - GCIPLTD Umbrella will guarantee at least 336 hours of work in any full 12 month period of employment commencing on your start date of continuous employment with the Company and this guarantee will apply annually thereafter on each anniversary thereof. This guarantee applies pro rata for part-time employees. During the period you are employed by GCIPLTD Umbrella, you cannot be involved with any other business or business activity unless written permission is sought from GCIPLTD Umbrella.</h5>
                        <br><br>

                        <h3>4. PLACE OF WORK</h3>
                        <h5>4.1 – You will, be required to work at such place as may be agreed with the Company in accordance with the terms of each Assignment. Your place of work for each Assignment will be notified to you in the Assignment Schedule.
                        <br><br>4.2 – You will not be required to work outside the UK for more than one month.</h5>
                        <br><br>

                        <h3>5. PAY AND BENEFITS</h3>
                        <h5>5.1 – During each Assignment the Company will pay you such sums as will be agreed in relation to that speciﬁc Assignment (“wages”), which will be conﬁrmed in writing in the relevant Assignment Schedule. As a minimum, your gross wages will be at least the National Minimum Wage rate applicable from time to time for each hour worked. You will be paid weekly in arrears by credit transfer, on or around the Friday of each week or such day as agreed.
                            <br><br>5.2 – Subject to any applicable statutory entitlement (including for example paid leave and statutory sick pay) and the terms of this contract, you are not entitled to receive payment from the Company for time not spent working on an Assignment (including periods when the Company has been unable to obtain an Assignment for you or you have chosen not to accept any Assignment offered).
                            <br><br>5.3 – The Company reserves the right to deduct from your wages or other sums due to you any sums which you owe to the Company at any time.
                            <br><br>5.4 – Currently there are no bonuses payable in relation to any Assignment.
                            <br><br>5.5 – You are not currently entitled to any benefits during your employment.
                            <br><br>5.6 - The rate the agency or client will pay the company (GCIPLTD Umbrella) is not solely yours or Employee’s gross pay. It is intended to cover:
                            <ul>
                                <li>Your (Employee) gross pay;</li>
                                <li>Your (Employee) employment overheads (including employer’s National Insurance and workplace pension auto-enrolment contributions, and apprenticeship levy)</li>
                                <li>The Company’s margin; and</li>
                                <li>Provision for Your (Employee) holiday pay.</li>
                                <li>You will find an illustration of the expected gross pay in the Key Information Document given to you on successful onboarding on registration.</li>
                            </ul>
                            <br><br>5.7 - I confirm that I will provide my Professional Registration Number (PIN) to my employer where applicable, to ensure annual professional compliance and also enable the employer to claim back the annual professional subscription(s) via payroll weekly or monthly or as appropriate.
                        </h5>
                        <br><br>

                        <h3>6. TRAINING</h3>
                        <h5>6.1 – You may be required to undertake training provided by clients from time to time, including in relation to the client’s working practices and health and safety.</h5>
                        <br><br>

                        <h3>7. HOURS OF WORK</h3>
                        <h5>7.1 – There are no ﬁxed or guaranteed minimum hours for this employment and you will work such hours as may be agreed between you and the Company in accordance with each Assignment and as set out in the relevant Assignment Schedule.
                        <br><br>7.2 – You will cooperate and comply with such means of recording working time and expenses as we may reasonably specify from time to time.</h5>
                        <br><br>

                        <h3>8. HOLIDAYS</h3>
                        <h5>8.1 – You are entitled to the equivalent of 5.6 weeks’ paid holiday each holiday year. This entitlement amounts to 28 days’ paid annual leave in the full working year (260 working days) for a full-time employee and part-time employees will receive this entitlement pro rata. This entitlement is inclusive of the usual bank and public holidays in England and Wales. Your holiday entitlement may change from time to time in line with the Agency Workers Regulations 2010 (“AWR”) once the Qualifying Period (as defined in the AWR) is met.
                        <br><br>8.2 – The Company’s holiday year commences on 1st January and ends on 31st December each year. Any accrued entitlement for holiday must be taken within the holiday year or before termination of your employment (if sooner).
                        <br><br>8.3 – The Company will pay holiday pay at the equivalent of 5.6 weeks as calculated from time to in line with actual hours covered to reflect any increased entitlement under the AWR or other adjustment. By default, the Company will roll up your holiday pay and make payments of holiday pay to you each week in addition to your wages (when you are on an Assignment). These payments will be equivalent to 5.6 weeks of your wages and will be paid to you in addition to your wages. This is essentially a pre-payment in respect of paid annual leave. For the avoidance of doubt, when you take holidays you will not receive any additional payment in respect of the leave actually taken, because payment for such leave has been paid in advance. This payment will be identified separately on your pay-slip to clearly show the amount of holiday pay received.
                        <br><br>8.4 – Alternatively, if you would prefer to have your holiday pay paid when you take annual leave, instead of paid out to you in advance (rolled-up), the Company will pay you holiday pay (calculated on the same basis as above) as and when you actually take your accrued holiday. You should contact the Company if you would prefer to receive your holiday pay in this way and have not already informed the Company. On termination of employment, you will be entitled to be paid in lieu of accrued but untaken holiday if you have chosen this option. The amount of such payment in lieu shall be one hour's pay for each accrued but untaken hour of your holiday entitlement.
                        <br><br>8.5 – All holidays must be taken at times convenient to the Company, and you must give reasonable notice to the Company of your intention to take holidays.
                        <br><br>8.6 – Holidays will be limited to a period of no longer than 10 consecutive working days except by prior agreement with the Company. Holiday can be taken during and in-between Assignments, subject to the Company’s and/or client’s business requirements. The Company may require you to take holidays on specific days as notified to you.</h5>
                        <br><br>

                        <h3>9. OTHER LEAVE</h3>
                        <h5>9.1 – You may be eligible to take the following types of paid leave, subject to any statutory eligibility requirements or conditions and the Company's rules applicable to each type of leave in force from time to time:
                        <ul>
                            <li>Statutory Maternity Leave;</li>
                            <li>Statutory Paternity Leave;</li>
                            <li>Statutory Adoption Leave;</li>
                            <li>Shared Parental Leave; and</li>
                            <li>Parental Bereavement Leave.</li>
                        </ul></h5>
                        <h5>9.2 – Further details of such leave are available from the Staff Handbook or from the Payroll Manager (payroll@gcipltd.com)</h5>
                        <br><br>

                        <h3>10. SICKNESS AND ABSENCE</h3>
                        <h5>10.1 – If you will be absent on account of sickness or injury from performing your normal duties you should inform the Company of the reason for absence as soon as possible but no later than 8.30am on the working day on which absence ﬁrst occurs and on each day thereafter so as to enable alternative arrangements to be made.
                        <br><br>10.2 – In respect of absence lasting seven days or less you need not produce a medical certiﬁcate unless speciﬁcally requested to do so. However, you must complete the Company’s self certiﬁcation form immediately on return to work after such absence. You must provide the Company with a medical certificate from your General Practitioner for periods of sickness absence in excess of seven days (including weekends) and each subsequent period of sickness absence.
                        <br><br>10.3 – The Company reserves the right to ask you at any stage of absence to undergo a medical examination by an independent doctor. Your refusal or failure to submit to a medical examination may be to your detriment as the Company will have no alternative but to make a decision about your continued employment without the beneﬁt of professional medical advice.
                        <br><br>10.4 – If you satisfy the qualifying conditions laid down by law, you will be entitled to receive statutory sick pay (SSP) at the prevailing rate in respect of any period of sickness or injury during an Assignment, but you will not be entitled to any other payments from the Company during such period. For the purpose of calculating your entitlement to SSP, qualifying days are Monday to Friday. Entitlement to SSP is subject to notiﬁcation of absence and production of medical certiﬁcates as required above.
                        <br><br>10.5 – Further details are outlined in Chapter 3 of the Staff Handbook.</h5>
                        <br><br>

                        <h3>11. PENSIONS</h3>
                        <h5>11.1 – The Company will at all times comply with the employer pension duties under Part 1 of the Pensions Act 2008. You are eligible to be enrolled into the NEST scheme. Further details of the pension scheme are available from the Payroll Manager on 01322 272 859 or info@gcipltd.com and from the NEST website: nestpensions.org.uk</h5>
                        <br><br>

                        <h3>12. NOTICE</h3>
                        <h5>12.1 – Your employment the employment may be terminated:
                        <ul>
                            <li>By you giving the Company not less than one week’s written notice of resignation of employment.</li>
                            <li>By the Company giving you not less than one week’s written notice of termination of employment for each completed year of continuous service, with a minimum of one week and a maximum of twelve weeks.</li>
                        </ul></h5>
                        <h5>12.2 – The Company reserves the right to make payment in lieu of part or all of your notice entitlement.
                        <br><br>12.3 – The Company may at its entire discretion for all or part of your notice period require you to perform whatever duties it may allocate to you, not to perform any duties, not to have contact with any clients, prospective clients or other employees or exclude you from the Company’s or any client’s premises. In such circumstances you will be required to remain readily contactable and available for work.
                        <br><br>12.4 – We shall be entitled to dismiss you at any time without notice or payment in lieu of notice if we reasonably consider that you have committed any serious breach of your obligations as an employee or committed any act of gross misconduct, or if you cease to be entitled to work in the UK.</h5>
                        <br><br>

                        <h3>13. CONFIDENTIALITY</h3>
                        <h5>13.1 – In this clause 13, “Confidential Information” means information (whether or not recorded in documentary form, or stored on any magnetic or optical disk or memory) relating to the Company's and/or its clients’ business, products, affairs and finances for the time being confidential to the Company or any of its clients and trade secrets including, without limitation, technical data and know-how relating to the Company's or its clients’ business or any of its or their business contacts, including in particular (by way of illustration only and without limitation) details of clients, customers, whether actual potential or past, employees, price information, rates charged for services oﬀered and all information on the Company’s or its clients’ client database.
                        <br><br>13.2 – You acknowledge that in the course of your employment and when working on Assignment you will have access to Confidential Information. You therefore agree to accept the restrictions in this clause 13.
                        <br><br>13.3 – You shall not (except in the proper course of your duties), either during your employment or at any time after its termination (however arising), use or disclose to any person, company or other organisation whatsoever (and shall use your best endeavours to prevent the publication or disclosure of) any Confidential Information. This shall not apply to:
                        <ul>
                            <li>any use or disclosure authorised by the Board or required by law;</li>
                            <li>any information which is already in, or comes into, the public domain other than through your unauthorised disclosure; or</li>
                            <li>any protected disclosure within the meaning of section 43A of the Employment Rights Act 1996.</li>
                        </ul></h5>
                        <br><br>
                        
                        <h3>14. RESTRICTIONS</h3>
                        <h5>14.1 – The following restrictions apply for six months after your employment with the Company has ended. During that period you will not without the prior written consent of the Company either on your own behalf or on behalf of any person ﬁrm or company, and whether directly or indirectly:
                        <ul>
                            <li>Procure or seek to procure orders or accept orders from, or do business with, any person who was a client of the Company or its appointed agent during the 12 months prior to the end of your employment with the Company and for whom you performed an Assignment during that period, in respect of services which are the same as or similar to those which you provided to that client in the course of that Assignment; or</li>
                            <li>Accept employment or engagement by, or provide personal services to, any client of the Company or its appointed agent for whom you have performed an Assignment during the twelve months prior to the end of your employment with the Company, the duties of which employment or engagement are the same as or similar to those services which you provided to that client in the course of that Assignment.</li>
                        </ul></h5>
                        <h5>14.2 – Nothing in this clause will prohibit the seeking or procuring of orders or the doing of business not relating or similar to the services described above.
                        <br><br>14.3 – If any of the above restrictions or provisions will be judged to be void it will to that extent be severed and the remaining provisions will continue to have full force and eﬀect.
                        <br><br>14.4 – The above restrictions are considered reasonable by the parties but in the event that any such restriction will be found to be void but would be valid if some part thereof were deleted it will apply with such modiﬁcation as may be necessary to make it valid and eﬀective.
                        <br><br>14.5 – If you are required to serve garden, the period of applicability of the restrictions contained in clause 14.1 shall be reduced by the period of garden leave which you have served.</h5>
                        <br><br>

                        <h3>15. PROPERTY</h3>
                        <h5>15.1 – You must deliver upon termination of employment, or at any other time at the request of the Company, all property belonging to the Company or any of its clients, including all papers, documents, keys, credit cards, vehicles, computers, information stored on a computer or any other data or information of any kind including copies of all of the above in whatever format. You may be required to sign an undertaking that all such property has been duly returned.
                        <br><br>15.2 – If any property is issued to you by us or by a client in connection with an Assignment, you will use it for no other purpose, take all proper care of it and return it at the end of your employment (earlier, if so required) in good serviceable condition, fair wear and tear only excepted.
                        <br><br>15.3 – All property of yours shall be at your risk at all times and we shall not be liable for any loss or damage to it, however such loss or damage may be caused.</h5>
                        <br><br>

                        <h3>16. GRIEVANCE AND DISCIPLINARY PROCEDURE</h3>
                        <h5>16.1 – Copies of the disciplinary and grievance procedures applicable to you are contained in the Staff Handbook and can be obtained from the Payroll Manager, but do not form part of your terms and conditions of employment.
                        <br><br>16.2 – If you are dissatisﬁed with any disciplinary decision relating to you (including a decision to dismiss you), you may appeal in writing to the Payroll Manager in accordance with the disciplinary procedure.
                        <br><br>16.3 – If you have any grievance relating to your employment you should in the ﬁrst instance refer the grievance, either orally or in writing, to the Payroll Manager, and the matter will be discussed informally. If you still remain dissatisﬁed you should continue to follow the grievance procedure which can be obtained from the Payroll Manager or the Staff Handbook.</h5>
                        <br><br>

                        <h3>17. SUSPENSION</h3>
                        <h5>17.1 – Where you are accused of an act of serious or gross misconduct you may be suspended from work on full pay pending the outcome of the disciplinary procedure. Such suspension is not a form of disciplinary action.</h5>
                        <br><br>

                        <h3>18. COLLECTIVE AGREEMENTS</h3>
                        <h5>18.1 – There are no collective agreements affecting your employment.</h5>
                        <br><br>

                        <h3>19. PERSONAL DATA</h3>
                        <h5>19.1 – The Company will collect and process information relating to you in accordance with the privacy notice which is on the Company’s website: www.gcipltd.com</h5>
                        <br><br>

                        <h3>20. CHANGES TO TERMS OF EMPLOYMENT</h3>
                        <h5>20.1 – The Company reserves the right to make reasonable changes to any of your terms and conditions of employment and will notify you in writing of such changes prior to their taking eﬀect.
                        <br><br>20.2 – Such changes will be deemed to have been accepted unless you notify the Company of any objection in writing within one month of being notiﬁed of the changes.</h5>
                        <br><br>

                        <h3>21. OBLIGATIONS OF EMPLOYEE</h3>
                        <h5>21.1 – Policies and Procedures – You must maintain the highest standards of honesty and fair dealing in your work for us. The utmost importance is attached to the observance of our policies and procedures.
                        <br><br>21.2 – Warranty – You warrant that you are not subject to any restriction (whether contractual or otherwise) which might restrict you from fully performing any of the duties of your employment.
                        <br><br>21.3 – Criminal charges – If you at any time have a Criminal Charge laid against you, or if you receive a summons to appear in the Magistrates Court (other than as a witness, in domestic proceedings, or for a Road Traﬃc oﬀence where the Court has no power to order endorsement of your driving licence) you must disclose it to us immediately. If you have had such a conviction in the past (other than a conviction which is ‘spent’ within the meaning of the Rehabilitation of Oﬀenders Act) you must do likewise.
                        <br><br>21.4 – Other work - You must devote the whole of your time, attention and abilities during your normal working hours to your duties under this Contract of Employment, and you may not under any circumstances during those hours whether directly or indirectly, undertake any other duties, of whatever kind. You must not take any other employment or engage in any way in any other business activity outside your normal working hours without ﬁrst obtaining our written consent.
                        <br><br>21.5 – Working Clothing – You must dress appropriately and ensure that your personal presentation is excellent at all times when you may come into contact with clients of ours; any protective clothing or equipment which may be necessary to minimise risks to your health or safety will be provided without charge.
                        <br><br>21.6 – Health and Safety – You must:
                        <ul>
                            <li>Familiarise yourself and comply at all times with all aspects of our Health and Safety policy, and with that of any client on whose premises you are working;</li>
                            <li>Comply with the requirements of the Health and Safety at Work Act 1974 (“Act”) and all other current relevant safety legislation, regulations, laws, codes of practice, standards, and requirements imposed by any competent authority (‘the Requirements’);</li>
                            <li>Take responsible care for the health and safety of yourself and any other person who may be aﬀected by your acts or omissions at work as required by section 7 of that Act;</li>
                            <li>As regards any duty imposed on us or on any other person by or under any relevant Requirement, cooperate with us or such other person so far as is necessary to enable that duty or requirement to be performed or complied with;</li>
                            <li>Not intentionally or recklessly interfere with or misuse anything provided in the interests of health, safety, or welfare in pursuance of any relevant statutory provisions.</li>
                        </ul></h5>
                        <br><br>
                        
                        <h3>22. GENERAL</h3>
                        <h5>22.1 – Status - it is not the intention of either of us that you should be or become an employee of any client for whom you may perform an Assignment.
                        <br><br>22.2 – Conduct of Employment Agencies and Employment businesses Regulations 2003 – The Company does not consider itself an employment business or employment agency for the purposes of the Conduct of Employment Agencies and Employment Businesses Regulations 2003 (“Conduct Regulations”). To the extent that the Conduct Regulations apply, the Company considers that it is a work-seeker and not an employment business. If you wish to opt out of the Conduct Regulations, then you should inform the Company of this so that a separate opt out agreement can be provided to you (if appropriate) for signature and a copy provided to the relevant employment business.
                        <br><br>22.3 – Governing Law and Jurisdiction – This contract and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with it or its subject matter or formation shall be governed by and construed in accordance with the law of England and Wales and shall be subject to the exclusive jurisdiction of the courts of England and Wales.</h5>
                        <br><br>

                        <h3>23. ENTIRE AGREEMENT</h3>
                        <h5>23.1 – This contract, together with any Assignment Schedule, constitutes the entire agreement between the parties in relation to its subject matter and supersedes any prior written or oral agreement between them relating to such subject matter and the parties conﬁrm that they have not entered into this contract on the basis of any representations that are not expressly incorporated in this contract.</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  //import { Card, Button, FormGroupInput, Tabs, TabPane } from '@/components';

  export default {
    /*components: {
      Card,
      Tabs,
      TabPane,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput
    },*/
    /*metaInfo() {
        return {
            meta: [
                // OpenGraph data
                {property: 'og:type', content: 'website'},
                {property: 'og:url', content: 'https://www.burnleysl.co.uk/'},
                {property: 'og:image', content: 'https://www.burnleysl.co.uk/icon.png'},
                {property: 'og:description', content: 'Burnley Sales & Lettings LTD was established by experienced landlords, who after many years of being dissatisfied with the service we were receiving, felt it was time to make a difference.'},
                // Google / Schema.org markup:
                {itemprop: 'description', content: 'Burnley Sales & Lettings LTD was established by experienced landlords, who after many years of being dissatisfied with the service we were receiving, felt it was time to make a difference.'},
                {itemprop: 'image', content: 'https://www.burnleysl.co.uk/icon.png'}
            ],
            link: [
                {rel: 'canonical', href: 'https://www.burnleysl.co.uk/'}
            ]
        }
    }*/
  }
</script>
<style>
</style>
