import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./registerServiceWorker";
import Vuelidate from 'vuelidate'
import VueMeta from 'vue-meta'

//plugins
import NowUIKit from './plugins/now-ui-kit';

Vue.use(Vuelidate)

Vue.config.productionTip = false;
Vue.use(NowUIKit);
Vue.use(VueMeta);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
