<template>
    <div class="wrapper">
        <div class="col-md-10 text-left">
            <div class="send-button" @click="scrollToElement()">
                <n-button type="primary" round size="lg">Skip to Sign</n-button>
            </div>
                
            <div v-html="code"></div>
        </div>

        <div class="col-md-4 mr-auto">
            <n-radio v-model="selector" label="0">
              Type (advised for mobile users)
            </n-radio>
            <n-radio v-model="selector" label="1">
              Draw
            </n-radio>
        </div>

        <div v-show="selector === '0'" class="col-md-4 mr-auto">
            <div>
                <h4 class="title" style="text-align: left;">Confirm your full name *</h4>
                <fg-input class="input-lg" :error="fullNameErrors" inputClasses="preview-overwrite" v-model="confirm" />
            </div>
        </div>

        <div v-show="selector === '1'" class="col-md-4 mr-auto">
            <h4 class="title" style="text-align: left;">Sign here *:</h4>
            <canvas class="sig-canvas"
                @touchstart="touchStart"
                @touchmove="touchMove"
                @touchend="touchEnd"
                ref="canvasF"
                @mousedown="mouseDown"
                @mousemove="mouseMove"
                @mouseup="mouseUp"
            ></canvas>
            <div style="display: block;">
                Note: If you are using a mobile device, choose the "Type" option
            </div>
            <div v-if="selector" class="col-md-4 mr-auto">
                <n-button @click.native="clearCanvas()" type="secondary" round block size="sm">Clear</n-button>
            </div>
        </div>

        <div class="col-lg-9 text-center ml-auto mr-auto col-md-6">
            <div class="send-button" @click="submit()">
                <n-button type="primary" :disabled="disabled" round block size="lg">Submit</n-button>
                <div :class="statusClass" style="display: block;" v-if="formSent">
                    {{ resMsg }}
                </div>
                <div class="text-danger invalid-feedback mt-2" style="display: block;"  v-if="this.$v.$dirty">
                    {{ signErrors }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Button, Radio, FormGroupInput } from '@/components'
import { required, sameAs } from "vuelidate/lib/validators"
import formSubmission from '../services/Form'
import { getTemplate } from '../previewTemplates'

export default {
    components: {
      [Button.name]: Button,
      [Radio.name]: Radio,
      [FormGroupInput.name]: FormGroupInput
    },
    props: { form: Object },
    data() {
        return{
            selector: '1',
            confirm: undefined,
            points: [],
            canvasTxt: null,
            startX: 0,
            startY: 0,
            moveY: 0,
            moveX: 0,
            endY: 0,
            endX: 0,
            w: null,
            h: null,
            isDown: false,
            color: "#000",
            linewidth: 3,
            isDraw: false, //Signature tag
            code: '',
            errors: {
                generalMsg: 'One or more fields have an error. Please check and try again.',
                reqMsg: 'This field is required',
                numMsg: 'Enter a number',
                decMsg: 'Enter a decimal number',
                emailMsg: 'Enter valid email address'
            },
            resMsg: '',
            statusClass: '',
            formSent: false,
            disabled: false,
            valid: false
        }
    },

    validations: {
        confirm: { required },
        //points: { sameAs: sameAs( () => [] ) }
    },

    watch: {
      selector: function (val) {
        if(val === "1") { // canvas selected
            this.confirm = undefined
        } else if (val === "0") {
            this.confirm = this.form.personal.firstName + ' ' + this.form.personal.lastName
            this.clearCanvas()
        }
      }
    },

    computed: {
        fullNameErrors() {
            if (this.$v.confirm.$error) {
                if (!this.$v.confirm.required && this.selector === '1') {
                    return this.errors.reqMsg + ' ' + this.$v.confirm.required
                } else {
                    return ''
                }
            }
        },
        signErrors() {
            if (this.confirm === '' && this.selector === '0') {
                return 'Please type in your name'
            } else if (this.selector === '1' && this.points.length === 0) {
                return 'Please sign'
            } else {
                return ''
            }
        }
    },

    methods: {
        async submit () {
            /*console.log(this.$v)
            console.log(this.points.length)
            console.log(this.signErrors)*/
            try {
                this.$v.$touch()
                if((this.$v.confirm.required || this.selector === '1' && this.points.length > 0) && this.formSent === false) {
                    this.formSent = true
                    this.disabled = true

                    if(this.confirm === undefined) {
                        this.form.sig = this.$refs.canvasF.toDataURL("image/png") // check if canvas is empty first
                    } else {
                        this.form.fullName = this.confirm
                    }

                    const now = new Date()

                    this.form.formDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds()) // +1 hour for prod?
                    //new Date(year, month, day, hours, minutes, seconds, milliseconds);

                    grecaptcha.ready(function() {
                        grecaptcha.execute(process.env.VUE_APP_SITE_KEY, {action: 'submit'}).then(async function(token) {
                            this.form.token = token

                            const response = await formSubmission.create(this.form)
                            const msg = response.data

                            if (msg.includes('Sent')) {
                                this.statusClass = 'text-success mt-2'
                                this.resMsg = msg

                                this.$router.push({ name: 'form-complete' })
                            } else {
                                this.statusClass = 'text-danger invalid-feedback mt-2'
                                this.resMsg = msg
                            }
                        }.bind(this))
                    }.bind(this))
                }
            } catch (err) {
                this.statusClass = 'text-danger invalid-feedback mt-2'
                this.resMsg = "Something Went Wrong, try again later!"
                this.disabled = true
                console.log(err)
            }
        },
        scrollToElement() {
            const el = this.$el.getElementsByClassName('sig-canvas')[0];
            
            if (el) {
                el.scrollIntoView({behavior: 'smooth'});
            }
        },
      mouseDown(ev) {
        ev = ev || event;
        ev.preventDefault();

        let obj = {
            x: ev.offsetX,
            y: ev.offsetY
        };
        this.startX = obj.x;
        this.startY = obj.y;
        this.canvasTxt.beginPath();//Start painting
        this.points.push(obj);//Recording point
        this.isDown = true;
        },

        touchStart(ev) {
        ev = ev || event;
        ev.preventDefault();
        if (ev.touches.length == 1) {
            this.isDraw = true; //Signature tag
            let obj = {
            x: ev.targetTouches[0].clientX,
            y:
                ev.targetTouches[0].clientY -
                (document.body.offsetHeight * 0.5 +
                this.$refs.canvasF.offsetHeight * 0.1)
            }; //In the calculation value of y: document.body.offsetHeight*0.5 represents the remaining height of the board except for the signatureBox. this.$refs.canvasF.offsetHeight*0.1 is the height of the title in the board
            this.startX = obj.x;
            this.startY = obj.y;
            this.canvasTxt.beginPath();//Start painting
            this.points.push(obj);//Recording point
        }
      },

      mouseMove(ev) {
        ev = ev || event;
        ev.preventDefault();
        if (this.isDown) {
            let obj = {
            x: ev.offsetX,
            y: ev.offsetY
            };
            this.moveY = obj.y;
            this.moveX = obj.x;
            this.canvasTxt.moveTo(this.startX, this.startY);//Mobile paintbrush
            this.canvasTxt.lineTo(obj.x, obj.y);//Create Line
            this.canvasTxt.stroke();//Draw line
            this.startY = obj.y;
            this.startX = obj.x;
            this.points.push(obj);//Recording point
        }
      },

      touchMove(ev) {
        ev = ev || event;
        ev.preventDefault();
        if (ev.touches.length == 1) {
            let obj = {
            x: ev.targetTouches[0].clientX,
            y:
                ev.targetTouches[0].clientY -
                (document.body.offsetHeight * 0.5 +
                this.$refs.canvasF.offsetHeight * 0.1)
            };
            this.moveY = obj.y;
            this.moveX = obj.x;
            this.canvasTxt.moveTo(this.startX, this.startY);//Mobile paintbrush
            this.canvasTxt.lineTo(obj.x, obj.y);//Create Line
            this.canvasTxt.stroke();//Draw line
            this.startY = obj.y;
            this.startX = obj.x;
            this.points.push(obj);//Recording point
        }
      },

      mouseUp(ev) {
        ev = ev || event;
        ev.preventDefault();
        if (1) {
            let obj = {
            x: ev.offsetX,
            y: ev.offsetY
            };
            this.canvasTxt.closePath();//Collect pen
            this.points.push(obj);//Recording point
            this.points.push({ x: -1, y: -1 });
            this.isDown = false;
        }
      },

      touchEnd(ev) {
        ev = ev || event;
        ev.preventDefault();
        if (ev.touches.length == 1) {
            let obj = {
            x: ev.targetTouches[0].clientX,
            y:
                ev.targetTouches[0].clientY -
                (document.body.offsetHeight * 0.5 +
                this.$refs.canvasF.offsetHeight * 0.1)
            };
            this.canvasTxt.closePath();//Collect pen
            this.points.push(obj);//Recording point
            this.points.push({ x: -1, y: -1 });//Recording point
        }
      },

      clearCanvas() {
        this.canvasTxt.clearRect(
            0,
            0,
            this.$refs.canvasF.width,
            this.$refs.canvasF.height
        );
        this.points = [];
        this.isDraw = false;
      }
    },

    mounted() {
        this.code = getTemplate(this.form)
        let canvas = this.$refs.canvasF
        canvas.height = 73
        canvas.width = 343
        this.canvasTxt = canvas.getContext("2d")
        this.canvasTxt.strokeStyle = this.color
        this.canvasTxt.lineWidth = this.linewidth
    }
  }
</script>
<style scoped>
.sig-canvas {
  border: 2px dotted #CCCCCC;
  border-radius: 15px;
  cursor: crosshair;
}
</style>