<template>
    <div class="wrapper">
        <div class="page-header">
            <parallax class="page-header-image section-image-primary"
                 style="background-image: url('img/bg29.jpg')">
            </parallax>
            <div class="content-center">
                <h1 class="title">Working Time Directive</h1>
                <br><br>
                <h3>The organisation strives to provide a safe working environment and ensure the safety and well-being of all its workers.</h3>
            </div>
        </div>

        <div class="section-brand-2">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-left">
                        <h3 class="title">1.0 Policy Statement</h3>
                        <h5><strong>1.1 -</strong> Global Challenge Investments Pvt Ltd Umbrella (GCIPLTD Umbrella) is committed to the health and safety of its employees and acknowledges its obligations within Working Time Regulations. The organisation strives to provide a safe working environment and ensure the safety and wellbeing of all its workers. The organisation seeks to ensure that workers do not exceed reasonable working hours to provide for a satisfactory balance between work and personal life. The organisation is also committed to ensuring that workers’ health is not compromised by the workplace.
                        <br><br>
                        <strong> 1.2 - </strong>The European Working Time Regulations set down entitlements of employees to maximum working hours, rest periods, rest breaks whilst at work, annual leave and working arrangements for night workers.</h5>
                        <br><br>

                        <h3 class="title">2.0 Principles</h3>
                        <h5><strong>2.1 -</strong> This policy applies to all GCIPLTD Umbrella staff.
                        <br><br>
                        <strong>2.2 - </strong>Unless they consent, a worker should not work in excess of 48 hours per week on average. The organisation will take all reasonable steps to comply with this limit. Should the employee wish to opt out they should complete the form at the bottom of <strong> <a href="/employee-options">THIS PAGE</a></strong> and sign to confirm.
                        <br><br>
                        <strong>2.3 -</strong> Employees working for more than one employer should still not exceed the 48-hour limit with regards to their total average weekly working time. Secondary employment includes working on the bank or through another agency or employer.
                        <br><br>
                        <strong>2.4 -</strong> If an employee considers that he/she has been unfairly treated with regard to his/her working hours (for example being required to work excessive hours or being unreasonably refused overtime or (temporary) flexible working arrangements), he/she is requested to raise this informally with his/her line manager. If the worker's complaint relates to his/her manager, he/she is requested to raise it with a more senior manager. If a worker is not satisfied following this route, he/she has the right to raise a grievance in accordance with the organisation’s Grievance procedure.</h5>
                        <br><br>

                        <h3 class="title">3.0 Monitoring and Review</h3>
                        <h5><strong>3.1 -</strong> This policy will be reviewed periodically or if statutory changes are required.</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  export default {
  }
</script>
<style>
</style>
