<template>
    <div class="wrapper">
        <div class="page-header">
            <parallax class="page-header-image section-image-primary"
                 style="background-image: url('img/bg5.jpg')">
            </parallax>
            <div class="content-center">
                <h1 class="title">Holiday Pay Request</h1>
                <h3>If you wish to request your holiday pay, please fill out the form below and sign to confirm.</h3>
            </div>
        </div>

        <div class="section-brand-2">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-left">
                        <h2 class="title">Holiday Pay Request Form</h2>
                        
                        <h5><strong>Please fill out this form, and sign so that we can pay you the amount you have requested.</strong></h5>
                    </div>

                    <div class="col-lg-4 mr-auto col-md-4">
                    <label>First Name *</label>
                        <fg-input class="input-lg" :error="firstNameErrors" v-model="form.personal.firstName"/>
                    </div>
                    <div class="col-lg-4 mr-auto col-md-4">
                    <label>Last Name *</label>
                        <fg-input class="input-lg" :error="lastNameErrors" required v-model="form.personal.lastName"/>
                    </div>
                    <div class="col-lg-4 mr-auto col-md-4">
                    <label>Email *</label>
                        <fg-input class="input-lg" :error="emailErrors" v-model="form.personal.email"/>
                    </div>

                    <div class="col-lg-6 mr-auto col-md-6">
                      <label>Holiday Dates - From *</label>
                        <div class="datepicker-container">
                            <fg-input :error="holidayFromErrors">
                                <el-date-picker
                                        type="date"
                                        format="dd/MM/yyyy"
                                        placeholder="Type date then press enter e.g. 01/01/2000"
                                        v-model="form.holidayFrom">
                                </el-date-picker>
                            </fg-input>
                        </div>
                    </div>

                    <div class="col-lg-6 mr-auto col-md-6">
                      <label>Holiday Dates - To *</label>
                        <div class="datepicker-container">
                            <fg-input :error="holidayToErrors">
                                <el-date-picker
                                        type="date"
                                        format="dd/MM/yyyy"
                                        placeholder="Type date then press enter e.g. 01/01/2000"
                                        v-model="form.holidayTo">
                                </el-date-picker>
                            </fg-input>
                        </div>
                    </div>

                    <div class="col-lg-4 mr-auto col-md-4">
                    <label>Number of Days Requested *</label>
                        <fg-input class="input-lg" :error="daysRequestedErrors" v-model="form.daysRequested"/>
                    </div>

                    <div class="col-lg-4 mr-auto col-md-4">
                    <label>Amount Requested (£) *</label>
                        <fg-input class="input-lg" :error="amountRequestedErrors" v-model="form.amountRequested"/>
                    </div>

                    <div class="col-lg-4 mr-auto col-md-4">
                      <label>Date on which to be paid *</label>
                        <div class="datepicker-container">
                            <fg-input :error="payDateErrors">
                                <el-date-picker
                                        type="date"
                                        format="dd-MM-yyyy"
                                        placeholder="Type date then press enter e.g. 01-01-2000"
                                        v-model="form.payDate">
                                </el-date-picker>
                            </fg-input>
                        </div>
                    </div>
                    <br/>
                    
                    <div class="col-lg-6 text-center ml-auto mr-auto col-md-6">
                      <div class="send-button" @click="submit()">
                        <n-button type="primary" round block :disabled="disabled" size="lg">Next</n-button>
                        <div :class="statusClass" style="display: block;" v-if="formSent">
                            {{ resMsg }}
                        </div>
                        <div class="text-danger invalid-feedback mt-2" style="display: block;" v-if="$v.form.$anyError">
                            {{ errors.generalMsg }}
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <modal :show.sync="modals.classic" headerClasses="justify-content-center">
            <preview v-if="formSent" :form="form"></preview>
        </modal>
    </div>
</template>
<script>
  import { Card, Modal, Button, FormGroupInput, Tabs, TabPane } from '@/components';
  import { DatePicker, TimeSelect } from 'element-ui';
  import formSubmission from '../services/Form'
  import Preview from './Preview.vue'
  import { required, email, maxLength, numeric, decimal } from 'vuelidate/lib/validators'
  import axios from 'axios'

  export default {
    components: {
      Card,
      Tabs,
      Modal,
      TabPane,
      'preview': Preview,
      [Button.name]: Button,
      [DatePicker.name]: DatePicker,
      [FormGroupInput.name]: FormGroupInput,
      [TimeSelect.name]: TimeSelect
    },
    data() {
      return {
        modals: {
          classic: false
        },
        form: {
          personal: {
            firstName: '',
            lastName: '',
            email: ''
          },
          holidayFrom: '',
          holidayTo: '',
          daysRequested: '',
          amountRequested: '',
          payDate: '',
          formDate: null,
          sig: '',
          template: 'holiday',
          userIPAddress: ''
        },
        errors: {
          generalMsg: 'One or more fields have an error. Please check and try again.',
          reqMsg: 'This field is required',
          numMsg: 'Enter a number',
          decMsg: 'Enter a decimal number',
          emailMsg: 'Enter valid email address'
        },
        pickerOptions1: {
        shortcuts: [{
          text: 'Today',
          onClick (picker) {
            picker.$emit('pick', new Date())
          }
        },
        {
          text: 'Yesterday',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        },
        {
          text: 'A week ago',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      },
      datePicker: '',
      resMsg: '',
      statusClass: '',
      formSent: false,
      disabled: false
      }
    },

    validations: {
      form: {
        personal: {
          firstName: { required, maxLength: maxLength(50) },
          lastName: { required, maxLength: maxLength(50) },
          email: { required, email },
        },
        holidayFrom: { required },
        holidayTo: { required },
        daysRequested: { required, numeric },
        amountRequested: { required, decimal },
        payDate: { required }
      }
    },

    computed: {
      firstNameErrors() {
        if (this.$v.form.personal.firstName.$error) {
          if (!this.$v.form.personal.firstName.required) {
            return this.errors.reqMsg
          } else {
            return ''
          }
        }
      },

      lastNameErrors() {
        if (this.$v.form.personal.lastName.$error) {
          if (!this.$v.form.personal.lastName.required) {
            return this.errors.reqMsg
          } else {
            return ''
          }
        }
      },

      emailErrors() {
        if (this.$v.form.personal.email.$error) {
          if (!this.$v.form.personal.email.required) {
            return this.errors.reqMsg
          } else if (!this.$v.form.personal.email.email) {
            return this.errors.emailMsg
          } else {
            return ''
          }
        }
      },

      holidayFromErrors() {
        if (this.$v.form.holidayFrom.$error) {
          if (!this.$v.form.holidayFrom.required) {
            return this.errors.reqMsg
          } else {
            return ''
          }
        }
      },

      holidayToErrors() {
        if (this.$v.form.holidayTo.$error) {
          if (!this.$v.form.holidayTo.required) {
            return this.errors.reqMsg
          } else {
            return ''
          }
        }
      },

      daysRequestedErrors() {
        if (this.$v.form.daysRequested.$error) {
          if (!this.$v.form.daysRequested.required) {
            return this.errors.reqMsg
          } else if (!this.$v.form.daysRequested.numeric) {
            return this.errors.numMsg
          } else {
            return ''
          }
        }
      },

      amountRequestedErrors() {
        if (this.$v.form.amountRequested.$error) {
          if (!this.$v.form.amountRequested.required) {
            return this.errors.reqMsg
          } else if (!this.$v.form.amountRequested.decimal) {
            return this.errors.decMsg
          } else {
            return ''
          }
        }
      },

      payDateErrors() {
        if (this.$v.form.payDate.$error) {
          if (!this.$v.form.payDate.required) {
            return this.errors.reqMsg
          } else {
            return ''
          }
        }
      },

      generalErrors() {
        if (this.$v.form.holidayFrom.$error) {
          if (!this.$v.form.holidayFrom.required) {
            return this.errors.reqMsg
          } else {
            return ''
          }
        }
      },
    },

    methods: {
      async getIP() {
        const response = await axios.get(process.env.VUE_APP_LINK_IP)
        return response.data.ip
      },
      async submit () {
        try {
          this.$v.$touch()
          if(!this.$v.form.$anyError && this.formSent === false) { // formSent flag stops multiple submissions
            this.formSent = true
            this.disabled = true

            this.modals.classic = true
          }
        } catch (err) {
          this.statusClass = 'text-danger invalid-feedback mt-2'
          this.resMsg = "Something Went Wrong, try again later!"
          this.disabled = true
          console.log(err)
        }
      }
    },
    async mounted () {
      this.form.userIPAddress = await this.getIP()
    }
  }
</script>
<style>
</style>
