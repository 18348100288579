import Vue from "vue";
import Router from "vue-router";

// Navbars and footers
import MainNavbar from "./layout/MainNavbar.vue";
import MainFooter from "./layout/MainFooter.vue";

// Main pages
import Home from "./pages/Home.vue";

import Process from "./pages/Process.vue";

// Policies (footer)
import Paygap from "./pages/policy/Paygap.vue";
import SlaveryStatement from "./pages/policy/SlaveryStatement.vue";
import Privacy from "./pages/policy/Privacy.vue";

import EmployeeInfo from "./pages/information/EmployeeInfo.vue"
import FormComplete from "./pages/FormComplete.vue"
import AppForm from "./pages/AppForm.vue"
import HolidayForm from "./pages/HolidayForm.vue"

// Employee Information
import StaffHandbook from "./pages/information/StaffHandbook.vue";
import GDPR from "./pages/information/GDPR.vue";
import WorkingTimeDirective from "./pages/information/WorkingTimeDirective.vue";

import Contract from "./pages/information/Contract.vue";

Vue.use(Router);

const router = new Router({
  base: '/',
  mode: 'history',
  routes: [
    {
      path: "/",
      name: 'home',
      components: {default: Home, header: MainNavbar, footer: MainFooter}
    },
    {
      path: "/apply-now",
      name: "apply-now",
      components: {default: AppForm, header: MainNavbar, footer: MainFooter}
    },
    {
      path: "/our-process",
      components: {default: Process, header: MainNavbar, footer: MainFooter}
    },
    {
      path: "/pay-gap-statement",
      components: {default: Paygap, header: MainNavbar, footer: MainFooter}
    },
    {
      path: "/slavery-statement",
      components: {default: SlaveryStatement, header: MainNavbar, footer: MainFooter}
    },
    {
      path: "/privacy-policy",
      components: {default: Privacy, header: MainNavbar, footer: MainFooter}
    },
    {
      path: "/employee-options",
      name: "emp-options",
      components: {default: EmployeeInfo, header: MainNavbar, footer: MainFooter}
    },
    {
      path: "/working-time-directive-policy",
      components: {default: WorkingTimeDirective, header: MainNavbar, footer: MainFooter}
    },
    {
      path: "/staff-handbook",
      components: {default: StaffHandbook, header: MainNavbar, footer: MainFooter}
    },
    {
      path: "/data-protection-notice",
      components: {default: GDPR, header: MainNavbar, footer: MainFooter}
    },
    {
      path: "/contract-of-employment",
      components: {default: Contract, header: MainNavbar, footer: MainFooter}
    },
    {
      path: "/holiday-pay-request-form",
      name: "holiday-pay",
      components: {default: HolidayForm, header: MainNavbar, footer: MainFooter}
    },
    {
      path: "/form-complete",
      name: "form-complete",
      components: {default: FormComplete, header: MainNavbar},
      props: { default: { type: '' } },
      async beforeEnter (to, from, next) {
        if (from.name === 'apply-now') {
          to.matched[0].props.default.type = 'apply-now'
        } else if (from.name === 'holiday-pay') {
          to.matched[0].props.default.type = 'holiday-pay'
        } else if (from.name === 'emp-options') {
          to.matched[0].props.default.type = 'emp-options'
        } else {
          next('home')
        }
        next()
      }
    },
    {
      path: "*",
      redirect: { name: 'home' }
    }
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
});

export default router;
