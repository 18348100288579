<template>
    <div class="wrapper">
        <div class="page-header page-header-small">
            <parallax class="page-header-image section-image-primary"
                 style="background-image: url('img/bg24.jpg')">
            </parallax>
            <div class="content-center">
                <h1 class="title">Privacy Policy</h1>
            </div>
        </div>

        <div class="section-brand-2">
            <div class="container">
                <div class="row">
                    <div class="col-md-10 text-left">
                        <h2>What is the purpose of this policy?</h2>
                        <h5>Global Challenge Investments Pvt Ltd (GCIPLTD Umbrella) is committed to protecting the privacy and security of your personal information.
                        <br><br>
                        This privacy notice describes how we collect and use personal information about you during and after your working relationship with us, in accordance with the General Data Protection Regulation (GDPR). It applies to all employees, workers, contractors, candidates, individuals and clients or potential clients of ours who will be referred to as ‘Data subjects’ from here on.
                        <br><br>
                        GCIPLTD Umbrella is a “Data Controller”. This means that we are responsible for deciding how we hold and use personal information about you. We are required under data protection legislation to notify you of the information contained in this privacy notice. This notice applies to current and former employees, workers, contractors, candidates and individuals at clients or potential clients of ours in respect of whom we hold personal data. This notice does not form part of any contract of employment, a contract to provide services or any other contract. We may update this notice at any time without an advance notice.
                        <br><br>
                        It is important that you read this notice, together with any other privacy notice we may provide on specific occasions when we are collecting or processing personal information about you, so that you are aware of how and why we are using such information.</h5>
                        <br><br>

                        <h2>Data Protection Principles</h2>
                        <h5>We will comply with data protection law. This says that the personal information we hold about you must be:
                        <ol>
                            <li>Used lawfully, fairly and in a transparent</li>
                            <li>Collected only for valid purposes that we have clearly explained to you and not used in any way that is incompatible with those</li>
                            <li>Relevant to the purposes we have told you about and limited only to those</li>
                            <li>Accurate and kept up-to-date</li>
                            <li>Kept only as long as necessary for the purposes we have told informed you</li>
                            <li>Kept securely</li>
                        </ol></h5>
                        <br><br>

                        <h4><strong>The Kind of Information we may hold about You </strong></h4>
                        <h5>Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data). There are “special categories” of more sensitive personal data which require a higher level of protection.
                        <br><br>
                        <strong>Depending on the circumstances, we may collect, store, and use the following categories of personal information about you:</strong>
                        <ul>
                            <li>Personal contact details such as name, title, addresses, telephone numbers, and email</li>
                            <li>Date of birth</li>
                            <li>Gender</li>
                            <li>Marital status and dependents</li>
                            <li>Next of kin and emergency contact information</li>
                            <li>National Insurance number</li>
                            <li>Bank account details, payroll records and tax status</li>
                            <li>Salary, annual leave, pension and benefits</li>
                            <li>Start date(s) of job roles</li>
                            <li>Location of employment or workplace</li>
                            <li>Copy of identity documents, such as passport, driving license, utility bills</li>
                            <li>Recruitment information (including copies of right to work documentation, references and other information included in a CV or cover letter or as part of the application process).</li>
                            <li>Employment records (including job titles, work history, working hours, training records and professional memberships).</li>
                            <li>Salary / payments history.</li>
                            <li>Performance information</li>
                            <li>Disciplinary and grievance</li>
                            <li>CCTV footage and other information obtained through electronic means such as telephone recordings, email usage data, internet usage data and swipe card records</li>
                            <li>Information about your use of our information and communications systems</li>
                            <li>Photographs</li>
                        </ul></h5>
                        <br><br>

                        <h2>How may your personal information be collected</h2>
                        <h5>We may collect personal information about employees, workers, contractors, candidates and individuals at clients or potential clients of ours in a number of different ways. This includes through an application process, either directly from data subjects via phone or our website (www.gcipltd.com) or sometimes from another employment agency. All information collected from the data subject will only be used for the particular service they have opted for, unless we receive explicit consent from the data subject.</h5>
                        <br><br>

                        <h2>How we may use information about you</h2>
                        <h5>We will only use your personal information when the law allows us to. Most commonly, we may use your personal information in the following circumstances:
                        <ol>
                            <li>Where we are taking steps at your request prior to entering into a contract.</li>
                            <li>Where we need to perform a contract which we have entered into with you.</li>
                            <li>Where we need to comply with a legal obligation.</li>
                            <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
                            <li>We may also use your personal information in the following situations, which are likely to be rare.</li>
                            <li>Where it is necessary to protect the vital interests of you or another person.</li>
                            <li>Where it is needed in the public interest or for official purposes.</li>
                        </ol></h5>
                        <br><br>

                        <h4><strong>Situations in which we may use your personal information</strong></h4>
                        <h5>We may need the categories of information (in the section ‘The kind of information we hold about you’) for the reasons set out above (‘How we may use information about you’). Primarily this will be where we are taking steps at your request prior to entering into a contract and to perform a contract we have entered into with you (or your employer or a company related to you), and in some cases we may use your personal information to pursue legitimate interests of our own or those of third parties, provided your interests and fundamental rights do not override those interests.</h5>
                        <br><br>
                        <h5>The situations in which we may process your personal information are listed below. The list below indicates the categories of data which may be involved in processing your personal information (depending on the circumstances). The list also indicates, by numbers at the end of each category corresponding to the list in the box above ‘How we may use information about you’, the purpose or purposes for which we may process your personal information (depending on the circumstances).
                        <ul>
                            <li>Making a decision about your recruitment or [1]</li>
                            <li>Providing you with a quote regarding our services [1]</li>
                            <li>Determining or agreeing with you (or your employer or a company related to you) the terms on which we work [1]</li>
                            <li>Checking you are legally entitled to work in the [1]</li>
                            <li>Paying you and, if you are an employee, deducting tax and National Insurance [2]</li>
                            <li>Providing contractual benefits to you. [2]</li>
                            <li>Liaising with your pension provider. [2]</li>
                            <li>Administering the contract we have entered into with [2]</li>
                            <li>Business management and planning, including accounting and [2], [3], [4]</li>
                            <li>Conducting performance reviews, managing performance and determining performance [2]</li>
                            <li>Making decisions about salary reviews and [2]</li>
                            <li>To assess your suitability for potential roles with [1], [2], [4]</li>
                            <li>To contact you about a potential role with a [1], [2], [4]</li>
                            <li>To contact you about your service [1], [2], [4]</li>
                            <li>To propose a candidate for engagement (either on a temporary or permanent employment basis) by your [1], [2], [4]</li>
                            <li>Conducting a credit check on you prior to you commencing work for a [1], [2], [3], [4]</li>
                            <li>Assessing qualifications for a particular job or task, including decisions about [1] [2]</li>
                            <li>Gathering evidence for possible grievance or disciplinary [2]</li>
                            <li>Making decisions about your continued employment or [1], [2], [3]</li>
                            <li>Making arrangements for the termination of our working [2], [3]</li>
                            <li>Education, training and development [2], [3]</li>
                            <li>Dealing with legal disputes involving you, or other employees, workers and contractors, including accidents at [2], [3]</li>
                            <li>Ascertaining your fitness to [1], [2], [3]</li>
                            <li>Managing sickness absence. [2], [3]</li>
                            <li>Complying with health and safety [2], [3]</li>
                            <li>To prevent fraud. [1], [2], [3]</li>
                            <li>Equal opportunities [1], [2], [3]</li>
                            <li>To contact you with information about our [1], [2], [4]</li>
                            <li>To monitor your use of our information and communication systems to ensure compliance with our IT [1], [2], [3]</li>
                            <li>To ensure network and information security, including preventing unauthorised access to our computer and electronic communications systems and preventing malicious software [1], [2], [3]</li>
                            <li>To conduct data analytics studies to review and better understand data subject retention and attrition [4]</li>
                        </ul></h5>
                        <h5>Some of the above grounds for processing will overlap and there may be several grounds which justify our use of your personal information.</h5>
                        <br><br>
                        <h4><strong>Situations in which we may use your personal information</strong></h4>
                        <h5>If you fail to provide certain information when requested, we may not be able to perform the contract we have entered into with you (such as paying you or providing a service), or we may be prevented from complying with our legal obligations (such as to ensure the health and safety of our workers).</h5>
                        <br><br>

                        <h2>Change of purpose</h2>
                        <h5>We will only use your personal information for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If we need to use your personal information for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so. We may process your personal information without your knowledge or consent where this is required or permitted by law. How we may use special categories personal information.</h5>
                        <br><br>
                        <h5>Special categories” of sensitive personal data require higher levels of protection. We need to have further justification for collecting, storing and using this type of personal information. We may process special categories of personal information in the following circumstances:
                        <ol>
                            <li>Where we need to carry out our legal obligations or exercise specific rights (either yours or ours) related to employment and in line with our data protection</li>
                            <li>Where it is needed in the public interest, such as for equal opportunities monitoring, in relation to our occupational pension scheme, and in line with our data protection</li>
                            <li>Where it is needed to assess your working capacity on health grounds, subject to appropriate confidentiality safeguards.</li>
                            <li>In limited circumstances, with your explicit written</li>
                        </ol></h5>
                        <br><br>
                        <h5>Less commonly, we may process this type of information where it is needed in relation to legal claims or where it is needed to protect your interests (or someone else’s interests) and you are not capable of giving your consent, or where you have already made the information public. We may also process such information about members or former members in the course of legitimate business activities with the appropriate safeguards.</h5>
                        <br><br>

                        <h2>Our obligations as an employer</h2>
                        <h5>We may use special categories of personal information in the following ways:
                        <ul>
                            <li>We may use information relating to leaves of absence, which may include sickness absence or family related leaves, to comply with employment and other laws.</li>
                            <li>We may use information about your physical or mental health, or disability status, to ensure your health and safety in the workplace and to assess your fitness to work, to provide appropriate workplace adjustments, to monitor and manage sickness absence and to administer benefits.</li>
                            <li>We may use information about your race or national or ethnic origin, religious, philosophical or moral beliefs, or your sexual life or sexual orientation, to ensure meaningful equal opportunity monitoring and reporting.</li>
                        </ul></h5>
                        <br><br>

                        <h4><strong>Do we need your consent?</strong></h4>
                        <h5>We do not need your consent if we use special categories of your personal information in accordance with our written policy to carry out our legal obligations or exercise specific rights. In limited circumstances, we may approach you for your written consent to allow us to process certain particularly sensitive data. If we do so, we will provide you with full details of the information that we would like and the reason we need it, so that you can carefully consider whether you wish to consent. You should be aware that it is not a condition of your contract with us that you agree to any request for consent from us.</h5>
                        <br><br>

                        <h4><strong>Information about criminal convictions</strong></h4>
                        <h5>We may only use information relating to criminal convictions where the law allows us to do so. This will usually be where such processing is necessary to carry out our obligations and provided we do so in line with our data protection policy. Less commonly, we may use information relating to criminal convictions where it is necessary in relation to legal claims, where it is necessary to protect your interests (or someone else’s interests) and you are not capable of giving your consent, or where you have already made the information public.</h5>
                        <br><br>

                        <h4><strong>We envisage that we may hold information about criminal convictions</strong></h4>
                        <h5>We will only collect information about criminal convictions if it is appropriate given the nature of the role and where we are legally able to do so. Where appropriate, we will collect information about criminal convictions as part of the recruitment process or we may be notified of such information directly by you in the course of you working for us. We may use information about criminal convictions and offences in the following ways:</h5>
                        <ul>
                            <li>To consider your suitability for a role with clients or with us.</li>
                        </ul>
                        <br><br>

                        <h2>Automated Decision Making</h2>
                        <h5>Automated decision-making takes place when an electronic system uses personal information to make a decision without human intervention. We are allowed to use automated decision-making in any of the following circumstances:
                        <ol>
                            <li>Where it is necessary to perform the contract with you and appropriate measures are in place to safeguard your rights.</li>
                            <li>Where we have notified you of the decision and given you 21 days to request a reconsideration</li>
                            <li>In limited circumstances, with your explicit written consent and where appropriate measures are in place to safeguard your rights.</li>
                        </ol></h5>
                        <br>
                        <h5>If we make an automated decision on the basis of any special category data, we must have either your explicit written consent or it must be justified in the public interest, and we must also put in place appropriate measures to safeguard your rights.</h5>

                        <h5>You will not be subject to decisions which have a significant impact on you based solely on automated decision-making, unless we have a lawful basis for doing so and we have notified you.</h5>
                        <br><br>

                        <h2>Data sharing</h2>
                        <h4><strong>We may have to share your data with third parties, including third-party service providers and other entities in the group.</strong></h4>
                        <h5>We require third parties to respect the security of your data and to treat it in accordance with the law. We may transfer your personal information outside the EU. If we do, you can expect a similar degree of protection in respect of your personal information</h5>
                        <br>
                        <h4><strong>Why might you share my personal information with third parties?</strong></h4>
                        <h5>We may share your personal information with third parties where required by law, where it is necessary to administer the working relationship with you or where we have another legitimate interest in doing so.</h5>
                        <br>
                        <h4><strong>Which third party service providers process my personal information?</strong></h4>
                        <h5>“Third parties” includes third-party service providers (including contractors and designated agents) and other entities within our group. The following activities are carried out by third-party service providers: pension administration, benefits provision and administration, IT services, and recruitment services.</h5>
                        <br>
                        <h4><strong>How secure is my information with third party service providers and other entities?</strong></h4>
                        <h5>All our third-party service providers and other entities in the group are required to take appropriate security measures to protect your personal information in line with our policies. We do not allow our third-party service providers to use your personal data for their own purposes. We only permit them to process your personal data for specified purposes and in accordance with our instructions.</h5>
                        <br>
                        <h4><strong>What might you share my personal information with other entities?</strong></h4>
                        <h5>We may share your personal information with other entities in our group as part of our regular reporting activities on company performance, in the context of a business reorganisation or group restructuring exercise, for system maintenance support and hosting of data, or where we consider that another group entity is better placed to consider your suitability for a potential role.</h5>
                        <br>
                        <h4><strong>What about other third parties?</strong></h4>
                        <h5>We may share your personal information with other third parties, for example in the context of the possible sale or restructuring of the business. We may also need to share your personal information with a regulator or to otherwise comply with the law.</h5>
                        <br>
                        <h4><strong>Transferring information outside the EU</strong></h4>
                        <h5>We may transfer the personal information we collect about you to the following countries outside the EU for the reasons we have set out above: India. There is not an adequacy decision by the European Commission in respect of that country. This means that the country’s domestic law or international commitments have not been determined by the European Commission to provide an adequate level of protection for your personal information.</h5>

                        <h5>However, to ensure that your personal information does receive an adequate level of protection we have put in place the following appropriate measures to ensure that your personal information is treated by those third parties in a way that is consistent with and which respects the EU and UK laws on data protection: (i) organisations to which your personal data is sent outside of the EU have a legally binding contract with us, governed by English Law, requiring them to comply with UK legislation relating to data protection; (ii) all personal data is kept strictly confidential and can only be disclosed as required by the contract; and (iii) only those staff who need to have access to personal data for the performance of their contractual obligations are permitted to have access to it.</h5>
                        <br><br>

                        <h2>Data security</h2>
                        <h5>We have put in place measures to protect the security of your information, for example to backup and protect the integrity of our electronic communications and data storage systems. Details of these measures are available upon request. Third parties may only process your personal information on our instructions and where they have agreed to treat the information confidentially and to keep it secure. We have put in place appropriate security measures to prevent your personal information from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal information to those employees, agents, contractors and other third parties who have a business need to know. They may only process your personal information on our instructions and they are subject to a duty of confidentiality. We have put in place procedures to deal with any suspected data security breach and will notify you and any applicable regulator of a suspected breach where we are legally required to do so.</h5>
                        <br><br>

                        <h2>Data retention</h2>
                        <h4><strong>How long will you use my information for?</strong></h4>
                        <h5>We will only retain your personal information for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, auditing or reporting requirements. To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.</h5>
                        <br><br>
                        <h5>In some circumstances we may anonymise your personal information so that it can no longer be associated with you, in which case we may use such information without further notice to you. Once you are no longer an employee, worker, contractor or candidate of the company or an individual working at a clients or potential client of ours, we will retain and securely destroy your personal information in accordance with applicable laws and regulations.</h5>
                        <br><br>
                        
                        <h2>Rights of access, correction, erasure and restriction</h2>
                        <h4><strong>Your duty to inform us of changes</strong></h4>
                        <h5>It is important that the personal information we hold about you is accurate and current. Please keep us informed if your personal information changes during your working relationship with us.</h5>
                        
                        <h4><strong>Your rights in connection with personal information</strong></h4>
                        <h5>Under certain circumstances, by law you have the right to:
                        <ul>
                            <li>Request access to your personal information (commonly known as a “data subject access request”). This enables you to receive a copy of the personal information we hold about you and to check that we are lawfully processing</li>
                            <li>Request correction of the personal information that we hold about This enables you to have any incomplete or inaccurate information we hold about you corrected.</li>
                            <li>Request erasure of your personal This enables you to ask us to delete or remove personal information where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal information where you have exercised your right to object to processing (see below).</li>
                            <li>Object to processing of your personal information where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground. You also have the right to object where we are processing your personal information for direct marketing purposes.</li>
                            <li>Request the restriction of processing of your personal This enables you to ask us to suspend the processing of personal information about you, for example if you want us to establish its accuracy or the reason for processing it.</li>
                            <li>Request the transfer of your personal information to another party.</li>
                        </ul></h5>
                        <h5>If you want to review, verify, correct or request erasure of your personal information, object to the processing of your personal data, or request that we transfer a copy of your personal information to another party, please write to us.</h5>
                        <br>

                        <h4><strong>No fee usually required</strong></h4>
                        <h5>You will not have to pay a fee to access your personal information (or to exercise any of the other rights). However, we may charge a reasonable fee if your request for access is clearly unfounded or excessive. Alternatively, we may refuse to comply with the request in such circumstances.</h5>
                        <br>

                        <h4><strong>What we may need from you</strong></h4>
                        <h5>We may need to request specific information from you to help us confirm your identity and ensure your right to access the information (or to exercise any of your other rights). This is another appropriate security measure to ensure that personal information is not disclosed to any person who has no right to receive it.</h5>
                        <br><br>

                        <h2>Right to withdraw consent</h2>
                        <h5>In the limited circumstances where you may have provided your consent to the collection, processing and transfer of your personal information for a specific purpose, you have the right to withdraw your consent for that specific processing at any time. To withdraw your consent, please write to us. Once we have received notification that you have withdrawn your consent, we will no longer process your information for the purpose or purposes you originally agreed to, unless we have another legitimate basis for doing so in law.</h5>
                        <br><br>
                        
                        <h2>Embedded content from other websites</h2>
                        <h5>Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.</h5>
                        <br>
                        <h5>These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracing your interaction with the embedded content if you have an account and are logged in to that website.</h5>
                        <br><br>
                        
                        <h2>Data Protection Officer</h2>
                        <h5>We have appointed a data protection officer (DPO) to oversee compliance with this privacy notice. If you have any questions about this privacy notice or how we handle your personal information, please contact the DPO. You have the right to make a complaint at any time to the Information Commissioner’s Office (ICO), the UK supervisory authority for data protection issues.</h5>
                        <br><br>

                        <h2>Changes to this privacy notice</h2>
                        <h5>We reserve the right to update this privacy notice at any time, and we will provide you with a new privacy notice when we make any substantial updates. We may also notify you in other ways from time to time about the processing of your personal information.</h5>

                        <br>

                        &nbsp;
                        <h3>If you have any questions about this data protection/privacy notice, please contact GCIPLTD Umbrella on 01322 272 859 or 8 Conqueror Court, Staplehurst Road, Sittingbourne, Kent, ME10 5BH.</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  export default {
  }
</script>
<style>
</style>
