<template>
    <navbar position="fixed"
            :transparent="transparent"
            :color-on-scroll="colorOnScroll"
            menu-classes="mr-auto" class="navbar-expand-xl bg-primary">

        <template slot="navbar-menu">
            <li class="nav-item">
                <a
                class="nav-link"
                href="/"
                >
                <p>Home</p>
                </a>
            </li>

            <li class="nav-item">
                <a
                class="nav-link"
                href="/#about-us"
                >
                <p>About Us</p>
                </a>
            </li>

            <li class="nav-item">
                <a
                href="/our-process"
                class="nav-link"
                >
                <p>Our Process</p>
                </a>
            </li>

            <!--li class="nav-item">
                <a
                class="nav-link"
                href="/blog"
                >
                <p>Blog</p>
                </a>
            </li-->

            <li class="nav-item">
                <a
                class="nav-link"
                href="/#contact-us"
                >
                <p>Contact Us</p>
                </a>
            </li>
        </template>

        <template slot="navbar-menu2">
            <li class="nav-item">
                <a class="nav-link btn btn-neutral btn-simple"
                   href="/apply-now">
                    <p>APPLY NOW</p>
                </a>
            </li>
        </template>
    </navbar>
</template>

<script>
  import { DropDown, NavbarToggleButton, Navbar, NavLink } from '@/components'
  import { Popover } from 'element-ui'
  export default {
    name: 'main-navbar',
    props: {
      transparent: Boolean,
      colorOnScroll: String,
    },
    components: {
      DropDown,
      Navbar,
      NavbarToggleButton,
      NavLink,
      [Popover.name]: Popover
    },
    data() {
        return {
            image4: require("../../public/img/Emblem-white.png"),
        }
    }
  }
</script>

<style scoped>
.oppo {
    justify-content: flex-end !important;
}
</style>
