<template>
    <div class="wrapper">
        <div class="section-brand-2">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-left">
                        <div class="content-center">
                            <h1 class="title">UK Gender Pay Gap Statement</h1>
                        </div>

                        <br><br>

                        <h5>As a company we undertake rigorous equal pay audit. We have a duty to publish annual information relating to pay as outlined below:</h5>
                        <h4>1 - Publish yearly the following information</h4>
                        <h5><ul>
                            <li>The difference between the mean hourly rate of pay of male full-pay relevant employees and that of female full-pay relevant employees</li>
                            <li>The difference between the median hourly rate of pay of male full-pay relevant employees and that of female full-pay relevant employees</li>
                            <li>The difference between the mean bonus pay paid to male relevant employees and that paid to female relevant employees</li>
                            <li>The difference between the median bonus pay paid to male relevant employees and that paid to female relevant employees</li>
                            <li>The proportions of male and female relevant employees who were paid bonus pay (see regulation 12); and</li>
                            <li>The proportions of male and female full-pay relevant employees in the lower, lower middle, upper middle and upper quartile pay bands</li>
                        </ul></h5>
                        &nbsp;
                        <h4>2 - We publish the information required by paragraph (1) within the period of 12 months beginning with the snapshot date.</h4>
                        &nbsp;
                        <h4>3 - In compiling the information required by paragraph (1), we include data relating to a relevant employee if :</h4>
                        <h5><ul>
                            <li>The employee is employed under a contract personally to do work, and</li>
                            <li>That we do not have, and it is not reasonably practicable for us to obtain, the data.</li>
                        </ul></h5>
                        &nbsp;
                        <h2><strong>Meaning of “ordinary pay”</strong></h2>
                        <h4>4 - (1) In looking at the information, “ordinary pay” means:</h4>
                        <h5><ul>
                            <li>Basic pay</li>
                            <li>Allowances</li>
                            <li>Pay for piecework</li>
                            <li>Pay for leave</li>
                            <li>Shift premium pay</li>
                        </ul></h5>
                        <h4>5 - “Ordinary pay” does not include:</h4>
                        <h5><ul>
                            <li>Remuneration referable to overtime,</li>
                            <li>Remuneration referable to redundancy or termination of employment,</li>
                            <li>Remuneration in lieu of leave, or</li>
                            <li>Remuneration provided otherwise than in money.</li>
                        </ul></h5>
                        <h4>6 - <strong>Allowances:</strong></h4>
                        <h5><ul>
                            <li>“allowances” includes any sum paid with respect to—
                        <ul>
                            <li>Any duty of the employee, such as a duty in connection with the role of fire or bomb warden, that is ancillary to the main duties of the employee’s employment;</li>
                            <li>The location of the employment in a particular area;</li>
                            <li>The purchase, lease or maintenance of a vehicle;</li>
                            <li>The recruitment and retention of an employee; and</li>
                            <li>The purchase, lease or maintenance of an item;</li>
                        </ul>
                        </li>
                        </ul></h5>
                        <h5>but excludes any payment to reimburse expenditure wholly and necessarily incurred by the employee in the course of his or her employment.
                        <ul>
                            <li>“shift premium pay” means the difference between basic pay and any higher rate paid by the employer for work during different times of the day or night.</li>
                        </ul></h5>
                        &nbsp;
                        <h2><strong>Meaning of “bonus pay”</strong></h2>
                        <h4><strong>7- </strong>(1) Bonus Pay, means (subject to paragraph (2)) any remuneration that—</h4>
                        <h5><ul>
                            <li>Is in the form of money, vouchers, securities, securities options, or interests in securities,</li>
                            <li>Relates to profit sharing, productivity, performance, incentive or commission.</li>
                        </ul></h5>
                        <h5>“Bonus pay” does not include—
                        <ul>
                            <li>Ordinary pay,</li>
                            <li>Remuneration referable to overtime, or</li>
                            <li>Remuneration referable to redundancy or termination of employment.</li>
                        </ul></h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

  export default {
  }
</script>
<style>
</style>
