<template>
    <div class="wrapper landing-page">
        <div class="page-header">
            <parallax class="page-header-image"
                style="background-image: url('img/bg212.jpg')">
            </parallax>
            <div class="content-center">
                <div class="image-container1 logo-padding">
                    <img v-lazy="'img/GCIPLTD__Umbrella.png'" alt="GCIPLTD Umbrella logo">
                </div>
            </div>
        </div>

        <div id="about-us" class="page-header header-filter">
            <div class="page-header-image section-image-primary"
                style="background-image: url('img/banner8.jpg');">
            </div>
            <div class="content-center">
                <div class="container">
                    <div class="content-center">
                        <div class="row">
                            <div class="col-md-8 ml-auto text-right text-bg">
                                <h2 class="title">What We Do</h2>
                                <h3>“Providing a brand and a service that everyone can trust”</h3>
                                <h5>As former contractors ourselves, we understand the challenges you face
                                    and the many pitfalls that you need to avoid that are not always
                                    immediately apparent when talking to an Umbrella company.<br><br>

                                    Our services remove all your anxiety and stress by providing you with
                                    hassle free income payments in line with current legislation, meaning
                                    transparent and consistent levels of service, getting things right
                                    first time, every time.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-header header-filter">
            <div id="" class="page-header-image section-image-primary"
                style="background-image: url('img/banner9.jpg');">
            </div>
            <div class="content-center">
                <div class="container">
                    <div class="content-center">
                        <div class="row">
                            <div class="col-md-6 mr-auto text-left text-bg">
                                <h2 class="title">A process created with precision</h2>
                                <h5>GCIPLTD Umbrella provides you with everything you need at the right
                                    time using a process developed from years of personal experience.
                                </h5>
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--div class="page-header header-filter">
            <div class="page-header-image section-image-primary"
                style="background-image: url('img/bg22.jpg')">
            </div>
            <div class="content-center">
                <div class="container">
                    <div class="content-center">
                        <div class="row">
                            <div class="col-md-8 ml-auto text-right">
                                <h2 class="title">Choosing an umbrella company
                                    can be a long and winding task.</h2>
                                <h5>We have compiled several resources to help
                                    you choose the best umbrella company. Get an
                                    insight into what we do and how we do it. Get a
                                    handle on how best to utilise your freedom through contract work. 
                                </h5>
                                <br/>
                                <div class="buttons">
                                    <a href="/blog" class="btn btn-neutral btn-primary btn-lg mr-1">
                                        Blog
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div-->

        <div id="contact-us" class="contactus-1 section-image-primary" style="background-image: url('img/banner4.jpg')">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 ml-auto">
                        <card type="contact" raised header-classes="text-center">
                            <h4 slot="header" class="card-title">Contact Us</h4>
                            <h5>Open Monday – Friday<br>9:30 AM – 6:30 PM</h5>

                            <div class="row">
                                <div class="col-md-6 pr-2">
                                    <label>First Name *</label>
                                    <fg-input placeholder="Your First Name..."
                                        addon-left-icon="now-ui-icons users_circle-08"
                                        :error="firstNameErrors"
                                        v-model="form.firstName">
                                    </fg-input>
                                </div>
                                <div class="col-md-6 pl-2">
                                    <label>Last Name *</label>
                                    <fg-input placeholder="Your Last Name..."
                                        addon-left-icon="now-ui-icons text_caps-small"
                                        :error="lastNameErrors"
                                        v-model="form.lastName">
                                    </fg-input>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 pl-2">
                                    <label>Email address *</label>
                                    <fg-input placeholder="Your Email Address..."
                                        addon-left-icon="now-ui-icons ui-1_email-85"
                                        :error="emailErrors"
                                        v-model="form.email">
                                    </fg-input>
                                </div>
                                <div class="col-md-6 pl-2">
                                    <label>Phone Number *</label>
                                    <fg-input placeholder="Your Phone Number..."
                                        addon-left-icon="now-ui-icons tech_mobile"
                                        :error="mobileErrors"
                                        v-model="form.mobile">
                                    </fg-input>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 pr-2">
                                    <label>Agency Name</label>
                                    <fg-input placeholder="Your Agency's Name..."
                                        addon-left-icon="now-ui-icons business_bank"
                                        v-model="form.agency">
                                    </fg-input>
                                </div>
                                <div class="col-md-6 pr-2">
                                    <label>Agency Rate</label>
                                    <fg-input placeholder="Your Agency's Pay Rate..."
                                        addon-left-icon="now-ui-icons business_money-coins"
                                        v-model="form.agencyRate">
                                    </fg-input>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Your message</label>
                                <textarea name="message" class="form-control" id="message" rows="6" v-model="form.message"/>
                                <div class="text-danger invalid-feedback" style="display: block;" :class="{'mt-2': hasIcon}" v-if="!$v.form.message.maxLength">
                                  {{ errors.maxMsg1 }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-8" @click="submit()">
                                    <n-button type="primary" round :disabled="disabled" class="ml-auto mr-auto">Send Message</n-button>
                                    <div :class="statusClass" style="display: block;" v-if="formSent">
                                        {{ resMsg }}
                                    </div>
                                    <div class="text-danger invalid-feedback mt-2" style="display: block;" v-if="$v.form.$anyError">
                                        {{ errors.generalMsg }}
                                    </div>
                                </div>
                            </div>
                        </card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  import { Card, Button, FormGroupInput, Tabs, TabPane } from '@/components';
  import formSubmission from '../services/Form'
  import { required, email, maxLength, numeric } from 'vuelidate/lib/validators'

  export default {
    name: 'landing-page',
    bodyClass: 'landing-page',
    components: {
      Card,
      Tabs,
      TabPane,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput
    },
    data () {
        return {
            form: {
              firstName: '',
              lastName: '',
              email: '',
              mobile: '',
              agency: '',
              agencyRate: '',
              message: '',
              token: ''
            },

            errors: {
                generalMsg: 'One or more fields have an error. Please check and try again.',
                reqMsg: 'This field is required',
                numMsg: 'Enter a number',
                decMsg: 'Enter a decimal number',
                emailMsg: 'Enter valid email address',
                maxMsg1: 'Use fewer then 255 characters',
                maxMsg2: 'Use fewer then 40 characters'
            },
            successMsg: 'Message Sent!',
            resMsg: '',
            statusClass: '',
            formSent: false,
            disabled: false
        }
    },

    validations: {
      form: {
        firstName: { required, maxLength: maxLength(40) },
        lastName: { required, maxLength: maxLength(40) },
        email: { required, email },
        mobile: { required, numeric },
        message: { maxLength: maxLength(255) }
      }
    },

    metaInfo() {
        return {
            meta: [
                // OpenGraph data
                {property: 'og:type', content: 'website'},
                {property: 'og:url', content: 'https://www.gcipltd.com/'},
                {property: 'og:image', content: 'https://www.gcipltd.com/favicon.png'},
                {property: 'og:description', content: 'GCIPLTD Umbrella provides you with everything you need at the right time using a process developed from years of personal experience.'},
                // Google / Schema.org markup:
                {itemprop: 'description', content: 'GCIPLTD Umbrella provides you with everything you need at the right time using a process developed from years of personal experience.'},
                {itemprop: 'image', content: 'https://www.gcipltd.com/favicon.png'}
            ],
            link: [
                {rel: 'canonical', href: 'https://www.gcipltd.com/'}
            ]
        }
    },

    computed: {
      firstNameErrors() {
        if (this.$v.form.firstName.$error) {
          if (!this.$v.form.firstName.required) {
            return this.errors.reqMsg
          } else if (!this.$v.form.firstName.maxLength) {
            return this.errors.maxMsg2
          } else {
            return ''
          }
        }
      },

      lastNameErrors() {
        if (this.$v.form.lastName.$error) {
          if (!this.$v.form.lastName.required) {
            return this.errors.reqMsg
          } else if (!this.$v.form.lastName.maxLength) {
            return this.errors.maxMsg2
          } else {
            return ''
          }
        }
      },

      emailErrors() {
        if (this.$v.form.email.$error) {
          if (!this.$v.form.email.required) {
            return this.errors.reqMsg
          } else if (!this.$v.form.email.email) {
            return this.errors.emailMsg
          } else {
            return ''
          }
        }
      },

      mobileErrors() {
        if (this.$v.form.mobile.$error) {
          if (!this.$v.form.mobile.required) {
            return this.errors.reqMsg
          } else if (!this.$v.form.mobile.numeric) {
            return this.errors.numMsg
          } else {
            return ''
          }
        }
      },

      msgErrors() {
        if (this.$v.form.message.$error) {
          if (!this.$v.form.message.maxLength) {
            return this.errors.maxMsg1
          } else {
            return ''
          }
        }
      },
    },

    methods: {
        async submit () {
            try {
                this.$v.$touch()
                if(!this.$v.form.$anyError && this.formSent === false) {
                    this.formSent = true
                    this.disabled = true

                    /*const response = await formSubmission.send(this.form)
                    const msg = response.data

                    //this.buttonCaption = response.data
                    if (msg.includes('Message Sent')) {
                        this.statusClass = 'text-success mt-2'
                        this.resMsg = msg
                    } else {
                        this.statusClass = 'text-danger invalid-feedback mt-2'
                        this.resMsg = msg
                    }*/

                    grecaptcha.ready(function() {
                        grecaptcha.execute(process.env.VUE_APP_SITE_KEY, {action: 'submit'}).then(async function(token) {
                        this.form.token = token

                        const response = await formSubmission.send(this.form)
                        const msg = response.data

                        //this.buttonCaption = response.data
                        if (msg.includes('Message Sent')) {
                            this.statusClass = 'text-success mt-2'
                            this.resMsg = msg
                        } else {
                            this.statusClass = 'text-danger invalid-feedback mt-2'
                            this.resMsg = msg
                        }
                        }.bind(this))
                    }.bind(this))
                }
            } catch (err) {
                this.statusClass = 'text-danger invalid-feedback mt-2'
                this.resMsg = "Something Went Wrong, try again later!"
                this.disabled = true
                console.log(err)
            }
        }
    }
  }
</script>
<style>
.logo-padding {
    padding-top: 350px;
}

.font-test{
    font-size: 55px;
}

.section1-height{
    height: 550px;
}

.video-settings {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
}

.text-bg {
    background-color: rgba(61, 66, 71, 0.2);
}
</style>
