<template>
  <div class="wrapper">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/banner2.jpg')"
      >
      </parallax>
      <div class="content-center">
        <h1 class="title">Apply now</h1>
      </div>
    </div>
    <div class="section-brand-2">
      <div class="container">
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <h3 class="title">Personal Details</h3>
          </div>

          <div class="col-lg-6 mr-auto col-md-6">
            <label>First Name *</label>
            <fg-input class="input-lg" :error="firstNameErrors" v-model="form.personal.firstName" />
          </div>
          <div class="col-lg-6 mr-auto col-md-6">
            <label>Last Name *</label>
            <fg-input class="input-lg" :error="lastNameErrors" v-model="form.personal.lastName" />
          </div>

          <div class="col-lg-6 ml-auto mr-auto col-md-6">
            <label>Address Line 1</label>
            <fg-input class="input-lg" v-model="form.personal.address1" />
          </div>

          <div class="col-lg-6 mr-auto col-md-6">
            <label>Address Line 2</label>
            <fg-input class="input-lg" v-model="form.personal.address2" />
          </div>

          <div class="col-lg-4 ml-auto mr-auto col-md-12">
            <label>City</label>
            <fg-input class="input-lg" v-model="form.personal.city" />
          </div>

          <div class="col-lg-4 ml-auto mr-auto col-md-12">
            <label>County</label>
            <fg-input class="input-lg" v-model="form.personal.county" />
          </div>

          <div class="col-lg-4 ml-auto mr-auto col-md-12">
            <label>Postcode</label>
            <fg-input class="input-lg" v-model="form.personal.postcode" />
          </div>

          <div class="col-lg-6 ml-auto mr-auto col-md-12">
            <label>Email Address *</label>
            <fg-input
              class="input-lg"
              placeholder="address@email.com"
              :error="emailErrors"
              v-model="form.personal.email"
            />
          </div>

          <div class="col-lg-6 ml-auto mr-auto col-md-12">
            <label>Phone Number *</label>
            <fg-input class="input-lg" :error="mobileErrors" v-model="form.personal.mobile" />
          </div>

          <div class="col-lg-6 ml-auto mr-auto col-md-12">
            <label>Nationality *</label>
            <fg-input class="input-lg" :error="nationalityErrors" v-model="form.personal.nationality" />
          </div>

          <div class="col-lg-6 ml-auto mr-auto col-md-12">
            <label>Occupation</label>
            <fg-input class="input-lg" v-model="form.personal.occupation" />
          </div>

          <!--div class="col-lg-6 mr-auto col-md-6">
            <label>Date of Birth</label>
            <div id="upper" ref="upper" class="datepicker-container">
              <fg-input id="mid">
                <el-date-picker id="lower" ref="lower"
                  type="date"
                  format="dd/MM/yyyy"
                  default-value="01/01/1980"
                  :picker-options="datePickerOptions"
                  placeholder="Type date then press enter e.g. 01/01/2000"
                  v-model="form.personal.dob"
                >
                </el-date-picker>
              </fg-input>
            </div>
          </div-->
          <div class="col-lg-6 mr-auto col-md-6">
            <label>Date of Birth</label>
            <fg-input class="input-lg" v-model="form.personal.dob" />
          </div>

          <div class="col-lg-6 ml-auto mr-auto col-md-12">
            <label>National Insurance Number *</label>
            <fg-input class="input-lg" :error="NIErrors" v-model="form.personal.NI" />
          </div>

          <div class="col-md-12 ml-auto mr-auto">
            <h3 class="title">P45 Details</h3>
            <h5>
              To ensure we deduct the correct taxes, we require a P45 from your
              previous employer. If you are unable to obtain a P45, you are
              required to complete a P46*
            </h5>
            <n-radio v-model="form.p45.option" label="P45">
              I WILL PROVIDE A P45</n-radio>
            <n-radio v-model="form.p45.option" label="P46">
              I WILL PROVIDE A P46</n-radio>
              <div class="text-danger invalid-feedback mt-2" style="display: block;" v-if="!form.p45.option && $v.$dirty">
                {{ errors.reqMsg }}
              </div>
          </div>

          <div class="col-md-12 ml-auto mr-auto">
            <h3 class="title">Bank Details</h3>
          </div>

          <div class="col-lg-6 ml-auto mr-auto col-md-12">
            <label>Bank Name *</label>
            <fg-input class="input-lg" :error="bankNameErrors" v-model="form.bank.name"
            placeholder="e.g. Halifax"/>
          </div>

          <div class="col-lg-6 ml-auto mr-auto col-md-12">
            <label>Account Holder's Name *</label>
            <fg-input class="input-lg" :error="holderNameErrors" v-model="form.bank.holderName" />
          </div>

          <div class="col-lg-6 ml-auto mr-auto col-md-12">
            <label>Account Number *</label>
            <fg-input class="input-lg" :error="accountNumberErrors" v-model="form.bank.accountNumber" />
          </div>

          <div class="col-lg-6 ml-auto mr-auto col-md-12">
            <label>Sort-Code *</label>
            <fg-input
              class="input-lg"
              :error="sortCodeErrors"
              placeholder="Format: 010203"
              v-model="form.bank.sortCode"
            />
          </div>

          <div class="col-md-12 ml-auto mr-auto">
            <h3 class="title">Agency Details</h3>
          </div>

          <div class="col-lg-12 mr-auto col-md-12">
            <label>Agency Name *</label>
            <fg-input class="input-lg" :error="agencyNameErrors" v-model="form.agency.name" />
          </div>

          <div class="col-lg-6 mr-auto col-md-6">
            <label>Email</label>
            <fg-input class="input-lg" v-model="form.agency.email" />
          </div>

          <div class="col-lg-6 mr-auto col-md-6">
            <label>Payroll Email *</label>
            <fg-input class="input-lg" :error="payrollEmailErrors" v-model="form.agency.payrollEmail" />
          </div>

          <div class="col-lg-6 mr-auto col-md-6">
            <label>Consultant's Name</label>
            <fg-input class="input-lg" v-model="form.agency.consultantName" />
          </div>
          <div class="col-lg-6 mr-auto col-md-6">
            <label>Phone Number</label>
            <fg-input class="input-lg" :error="agencyPhoneErrors" v-model="form.agency.phoneNumber" />
          </div>

          <div class="col-lg-12 ml-auto mr-auto col-md-12">
            <label>Address 1</label>
            <fg-input class="input-lg" v-model="form.agency.address1" />
          </div>

          <div class="col-lg-12 mr-auto col-md-12">
            <label>Address 2</label>
            <fg-input class="input-lg" v-model="form.agency.address2" />
          </div>

          <div class="col-lg-4 ml-auto mr-auto col-md-12">
            <label>City</label>
            <fg-input class="input-lg" v-model="form.agency.city" />
          </div>

          <div class="col-lg-4 ml-auto mr-auto col-md-12">
            <label>County</label>
            <fg-input class="input-lg" v-model="form.agency.county" />
          </div>

          <div class="col-lg-4 ml-auto mr-auto col-md-12">
            <label>Postcode</label>
            <fg-input class="input-lg" v-model="form.agency.postcode" />
          </div>

          <div class="col-lg-6 mr-auto col-md-6">
            <label>Rate Per Hour/Day</label>
            <fg-input class="input-lg" v-model="form.agency.payRate" />
          </div>

          <div class="col-lg-6 mr-auto col-md-6">
            <label>Overtime Rate Per Hour/Day</label>
            <fg-input class="input-lg" v-model="form.agency.overtimePayRate" />
          </div>

          <div class="col-lg-4 ml-auto mr-auto col-md-12">
            <label>Expected Weekly Hours</label>
            <fg-input class="input-lg" v-model="form.agency.expectedHours" />
          </div>

          <div class="col-lg-4 ml-auto mr-auto col-md-12">
            <label>Weekly/Monthly Pay</label>
            <fg-input class="input-lg" v-model="form.agency.weeklyMonthlyPay" />
          </div>

          <div class="col-lg-4 ml-auto mr-auto col-md-12">
            <label>Start Date</label>
            <fg-input>
                <el-date-picker
                  type="date"
                  format="dd/MM/yyyy"
                  placeholder="Type date then press enter e.g. 01/01/2000"
                  v-model="form.agency.startDate"
                >
                </el-date-picker>
              </fg-input>
          </div>

          <div class="col-md-12 ml-auto mr-auto">
            <h3 class="title">Right To Work In The UK</h3>
            <h5>
              You are required by law to prove who you are, and that you are
              entitled to work in the UK, we also require proof of your address.
            </h5>
            <h5>
              <strong>
                PLEASE INDICATE BY TICKING THE RELEVANT BOX WHICH DOCUMENTS YOU
                WILL BE SENDING. WE REQUIRE CLEAR COPIES; FAX / EMAIL COPIES ARE
                ACCEPTABLE UNTIL WE RECEIVE THE ORIGINALS BY POST. SHOULD YOU
                NOT HAVE THE DOCUMENTS BELOW PLEASE CONTACT US IMMEDIATELY.*
              </strong>
            </h5>
            <n-radio v-model="form.rightToWork.option" label="GB">
              I have a current passport proving that you are a UK citizen or a
              citizen of the UK and colonies having the right to reside in the
              UK and recent utility bill or bank statement.
            </n-radio>
            <n-radio v-model="form.rightToWork.option" label="EU">
              I have a current passport or national identity card proving that
              you are a national of a European Economic Area country (including
              Switzerland) and a recent utility bill or bank statement.
            </n-radio>
            <n-radio v-model="form.rightToWork.option" label="Stay">
              I have a current passport endorsed to show that you are allowed to
              stay in the UK are are allowed to do any type of work or endorsed
              to show that you are exempt from immigration control, allowed to
              indefinitely in the UK, have the right abode in the UK, or no time
              limit on your stay and a recent utility bill or bank statement.
            </n-radio>
            <n-radio v-model="form.rightToWork.option" label="Other">
              Acceptable documents for right to work in the UK can be found on the
              Home Office website as shown: 
              <a href="https://www.gov.uk/government/publications/right-to-work-checklist" target="_blank">Home Office Website</a>
            </n-radio>
            <div class="text-danger invalid-feedback mt-2" style="display: block;" v-if="!form.rightToWork.option && $v.$dirty">
              {{ errors.reqMsg }}
            </div>
          </div>

          <div class="col-md-12 ml-auto mr-auto">
            <h3 class="title">Data Protection Notice</h3>
            <h5>
              The company will use your information together with other
              information for employment administration, payroll purposes,
              marketing and customer services. We will disclose your information
              to our clients, service providers and agents for these purposes.
              By signing this form, you give your consent to the Company
              processing your information, including sensitive personal data
              such as health data, whether obtained from you or from another
              source, for the above purposes. You also consent to our
              transferring information to countries which do not provide the
              same level of data protection as the UK if necessary for the above
              purposes. If we do make such a transfer, we will put a contract in
              place to ensure your information is protected.
            </h5>

            <br />

            <n-checkbox v-model="form.dataProtection.marketing">
              Please tick if you want us to disclose your information for
              marketing purposes.
            </n-checkbox>

            <br /><br />

            <h5>
              You have a right to ask for a copy of your information and to correct any inaccuracies. You should
              make an application to the Company’s Customer Services Department,
              giving 28 days notice. To make sure we follow your instructions
              correctly and to improve our service to you through training of
              our staff, we may monitor or record communications.
            </h5>

            <br />

            <n-checkbox :error="dataProtectionErrors" v-model="form.dataProtection.agree">
              I have read and understood the regulations available at
              www.bis.gov.uk and agree with this statement.
            </n-checkbox>

            <br /><br />

            <!--h5>
              <strong>
                Once you click 'Next' you will be asked to sign the terms of
                employment contract in order for your application to proceed
                further.
              </strong>
            </h5-->
          </div>

          <!--div class="col-md-12 ml-auto mr-auto">
            <h3 class="title">Refer A Friend</h3>
            <h5>
              If you were referred by someone registered with us, please provide their full name below:
            </h5>

            <div class="col-lg-6 mr-auto col-md-6">
              <label>Full Name</label>
              <fg-input class="input-lg" v-model="form.referral" />
            </div>
            <br /><br />
          </div-->

          <h5>
            <strong>
              Once you click 'Next' you will be asked to sign the terms of
              employment contract in order for your application to proceed
              further.
            </strong>
          </h5>

          <div class="col-lg-6 text-center ml-auto mr-auto col-md-6">
            <div class="send-button" @click="submit()">
              <n-button type="primary" round block :disabled="disabled" size="lg">Next</n-button>
              <div :class="statusClass" style="display: block;" v-if="formSent">
                  {{ resMsg }}
              </div>
              <div class="text-danger invalid-feedback mt-2" style="display: block;" v-if="$v.form.$anyError">
                {{ errors.generalMsg }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal :show.sync="modals.classic" headerClasses="justify-content-center">
      <preview v-if="formSent" :form="form"></preview>
    </modal>
  </div>
</template>
<script>
import { Card, Modal, Checkbox, Button, Radio, FormGroupInput, Tabs, TabPane, } from "@/components"
import { required, email, maxLength, numeric, decimal, sameAs } from "vuelidate/lib/validators"
import { DatePicker } from "element-ui"
import Preview from './Preview.vue'
import axios from 'axios'

export default {
  components: {
    Card,
    Tabs,
    Modal,
    TabPane,
    'preview': Preview,
    [Radio.name]: Radio,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [DatePicker.name]: DatePicker,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      modals: {
        classic: false
      },
      form: {
        personal: {
          firstName: '',
          lastName: '',
          address1: '',
          address2: '',
          city: '',
          county: '',
          postcode: '',
          email: '',
          mobile: '',
          nationality: '',
          occupation: '',
          dob: '',
          NI: '',
        },
        p45: {
          option: '',
        },
        bank: {
          name: '',
          holderName: '',
          accountNumber: '',
          sortCode: '',
        },
        agency: {
          name: '',
          email: '',
          payrollEmail: '',
          consultantName: '',
          phoneNumber: '',
          address1: '',
          address2: '',
          city: '',
          county: '',
          postcode: '',
          payRate: '',
          overtimePayRate: '',
          expectedHours: '',
          weeklyMonthlyPay: '',
          startDate: new Date(),
        },
        rightToWork: {
          option: '',
        },
        dataProtection: {
          marketing: false,
          agree: false
        },
        formDate: null,
        sig: '',
        template: "application",
        userIPAddress: '',
        //referral: null
      },

      datePickerOptions: {
        disabledDate (date) {
          const today = new Date()
          return date > new Date(today.getFullYear() - 16, today.getMonth(), today.getDate())
        }
      },

      errors: {
        generalMsg: 'One or more fields have an error. Please check and try again.',
        reqMsg: 'This field is required',
        numMsg: 'Enter a number',
        decMsg: 'Enter a decimal number',
        emailMsg: 'Enter valid email address'
      },
      resMsg: '',
      statusClass: '',
      formSent: false,
      disabled: false
    }
  },

  validations: {
    form: {
      personal: {
        firstName: { required },
        lastName: { required },
        //address1: { required },
        //city: { required },
        email: { required, email },
        mobile: { required, numeric },
        nationality: { required },
        //occupation: { required },
        //dob: { required },
        NI: { required },
      },
      p45: {
        option: { required },
      },
      bank: {
        name: { required },
        holderName: { required },
        accountNumber: { required, numeric },
        sortCode: { required }
      },
      agency: {
        name: { required },
        payrollEmail: { required, email },
        phoneNumber: { numeric }
      },
      rightToWork: {
        option: { required },
      },
      dataProtection: {
        agree: { sameAs: sameAs( () => true ) }
      },
    }
  },

  /*watch: {
    'form.personal.occupation': function (val) {
      //console.log(this)
      console.log(this.$refs.lower)
      //console.log(this.$refs.lower.userInput)
      //this.$refs.lower.valueOnOpen = '01/09/1943'

      //this.form.personal.dob = '01/09/1943'
    },
    'this.$refs.lower.userInput': function (val) {
      console.log(val)
      //console.log(this)
      //console.log(this.$refs.lower)
      //console.log(this.$refs.lower.userInput)
      //this.$refs.lower.userInput = '01/09/1943'
      this.$refs.lower.userInput.replace('-', '/')
    }
  },*/

  computed: {
    firstNameErrors() {
      if (this.$v.form.personal.firstName.$error) {
        if (!this.$v.form.personal.firstName.required) {
          return this.errors.reqMsg
        } else {
          return ''
        }
      }
    },

    lastNameErrors() {
      if (this.$v.form.personal.lastName.$error) {
        if (!this.$v.form.personal.lastName.required) {
          return this.errors.reqMsg
        } else {
          return ''
        }
      }
    },

    /*addressErrors1() {
      if (this.$v.form.personal.address1.$error) {
        if (!this.$v.form.personal.address1.required) {
          return this.errors.reqMsg
        } else {
          return ''
        }
      }
    },*/

    /*cityErrors() {
      if (this.$v.form.personal.city.$error) {
        if (!this.$v.form.personal.city.required) {
          return this.errors.reqMsg
        } else {
          return ''
        }
      }
    },*/

    emailErrors() {
      if (this.$v.form.personal.email.$error) {
        if (!this.$v.form.personal.email.required) {
          return this.errors.reqMsg
        } else if (!this.$v.form.personal.email.email) {
          return this.errors.emailMsg
        } else {
          return ''
        }
      }
    },

    mobileErrors() {
      if (this.$v.form.personal.mobile.$error) {
        if (!this.$v.form.personal.mobile.required) {
          return this.errors.reqMsg
        } else if (!this.$v.form.personal.mobile.numeric) {
          return this.errors.numMsg
        } else {
          return ''
        }
      }
    },

    nationalityErrors() {
      if (this.$v.form.personal.nationality.$error) {
        if (!this.$v.form.personal.nationality.required) {
          return this.errors.reqMsg
        } else {
          return ''
        }
      }
    },

    /*occupationErrors() {
      if (this.$v.form.personal.occupation.$error) {
        if (!this.$v.form.personal.occupation.required) {
          return this.errors.reqMsg
        } else {
          return ''
        }
      }
    },*/

    /*dobErrors() {
      if (this.$v.form.personal.dob.$error) {
        if (!this.$v.form.personal.dob.required) {
          return this.errors.reqMsg
        } else {
          return ''
        }
      }
    },*/

    NIErrors() {
      if (this.$v.form.personal.NI.$error) {
        if (!this.$v.form.personal.NI.required) {
          return this.errors.reqMsg
        } else {
          return ''
        }
      }
    },

    bankNameErrors() {
      if (this.$v.form.bank.name.$error) {
        if (!this.$v.form.bank.name.required) {
          return this.errors.reqMsg
        } else {
          return ''
        }
      }
    },

    holderNameErrors() {
      if (this.$v.form.bank.holderName.$error) {
        if (!this.$v.form.bank.holderName.required) {
          return this.errors.reqMsg
        } else {
          return ''
        }
      }
    },

    accountNumberErrors() {
      if (this.$v.form.bank.accountNumber.$error) {
        if (!this.$v.form.bank.accountNumber.required) {
          return this.errors.reqMsg
        } else if (!this.$v.form.bank.accountNumber.numeric) {
          return this.errors.numMsg
        } else {
          return ''
        }
      }
    },

    sortCodeErrors() {
      if (this.$v.form.bank.sortCode.$error) {
        if (!this.$v.form.bank.sortCode.required) {
          return this.errors.reqMsg
        } else {
          return ''
        }
      }
    },

    agencyNameErrors() {
      if (this.$v.form.agency.name.$error) {
        if (!this.$v.form.agency.name.required) {
          return this.errors.reqMsg
        } else {
          return ''
        }
      }
    },

    payrollEmailErrors() {
      if (this.$v.form.agency.payrollEmail.$error) {
        if (!this.$v.form.agency.payrollEmail.required) {
          return this.errors.reqMsg
        } else if (!this.$v.form.agency.payrollEmail.email) {
          return this.errors.emailMsg
        } else {
          return ''
        }
      }
    },

    agencyPhoneErrors() {
      if (this.$v.form.agency.phoneNumber.$error) {
        if (!this.$v.form.agency.phoneNumber.numeric) {
          return this.errors.numMsg
        } else {
          return ''
        }
      }
    },

    payRateErrors() {
      if (this.$v.form.agency.payRate.$error) {
        if (!this.$v.form.agency.payRate.decimal) {
          return this.errors.decMsg
        } else {
          return ''
        }
      }
    },
    
    overtimePayRateErrors() {
      if (this.$v.form.agency.overtimePayRate.$error) {
        if (!this.$v.form.agency.overtimePayRate.decimal) {
          return this.errors.decMsg
        } else {
          return ''
        }
      }
    },

    expectedHoursErrors() {
      if (this.$v.form.agency.expectedHours.$error) {
        if (!this.$v.form.agency.expectedHours.numeric) {
          return this.errors.numMsg
        } else {
          return ''
        }
      }
    },

    payErrors() {
      if (this.$v.form.agency.weeklyMonthlyPay.$error) {
        if (!this.$v.form.agency.weeklyMonthlyPay.numeric) {
          return this.errors.numMsg
        } else {
          return ''
        }
      }
    },

    /*startDateErrors() {
      if (this.$v.form.agency.startDate.$error) {
        if (!this.$v.form.agency.startDate.required) {
          return this.errors.reqMsg
        } else {
          return ''
        }
      }
    },*/

    rightToWorkErrors() {
      if (this.$v.form.rightToWork.option.$error) {
        if (!this.$v.form.rightToWork.option.required) {
          return this.errors.reqMsg
        } else {
          return ''
        }
      }
    },

    dataProtectionErrors() {
      if (this.$v.form.dataProtection.agree.$error) {
        if (!this.$v.form.dataProtection.agree.sameAs) {
          return this.errors.reqMsg
        } else {
          return ''
        }
      }
    }
  },

  methods: {
    async getIP() {
      const response = await axios.get(process.env.VUE_APP_LINK_IP)
      return response.data.ip
    },
    async submit() {
      try {
        this.$v.$touch()
        if(!this.$v.form.$anyError && this.formSent === false) {
          this.formSent = true
          this.disabled = true

          if (this.form.rightToWork.option === 'GB') {
            this.form.rightToWork.option = 'I have a current passport proving that you are a UK citizen or a citizen of the UK and colonies having the right to reside in the UK and recent utility bill or bank statement.'
          } else if (this.form.rightToWork.option === 'EU') {
            this.form.rightToWork.option = 'I have a current passport or national identity card proving that you are a national of a European Economic Area country (including Switzerland) and a recent utility bill or bank statement.'
          } else if (this.form.rightToWork.option === 'Stay') {
            this.form.rightToWork.option = 'I have a current passport endorsed to show that you are allowed to stay in the UK are are allowed to do any type of work or endorsed to show that you are exempt from immigration control, allowed to indefinitely in the UK, have the right abode in the UK, or no time limit on your stay and a recent utility bill or bank statement.'
          } else if(this.form.rightToWork.option === 'Other') {
            this.form.rightToWork.option = 'Other acceptable documents for right to work in the UK used'
          }

          this.modals.classic = true
        }
      } catch (err) {
        this.statusClass = 'text-danger invalid-feedback mt-2'
        this.resMsg = "Something Went Wrong, try again later!"
        this.disabled = true
        console.log(err);
      }
    },
  },

  async mounted () {
    this.form.userIPAddress = await this.getIP()
  }
};
</script>
<style>
</style>
<style scoped>
</style>
